<template>
  <b-modal :id="'modal' + lessonTitle" ref="treeDetailsShow" centered :title="lessonTitle" ok-only>
    <template v-for="data in treeData">
      <div v-for="unit in data.units" :key="unit.id">
        <template v-for="topic in unit.topics">
          <div v-for="gain in topic.gains" :key="gain.id">
            <template v-if="gain.id == getGainDetailId">
              <table class="table table-sm">
                <thead>
                  <tr role="row">
                    <th>Soru</th>
                    <th>Kitapçık</th>
                    <th>Anahtar</th>
                    <th>Cevap</th>
                    <th>Sonuç</th>
                  </tr>
                </thead>
                <tr v-for="(exam, examIndex) in gain.exams" :key="examIndex">
                  <td>
                    <template v-for="getExam in getExamList">
                      <span
                        v-if="exam.ID_SINAVBILGI == getExam.ID_SINAVBILGI"
                        :key="getExam.ID_SINAVBILGI"
                      >
                        {{ getExam.KOD }}
                      </span>
                    </template>
                    {{ exam.BLM }}.Bolum {{ exam.SN }}.Soru
                  </td>
                  <td>{{ exam.K }}</td>
                  <td>{{ exam.A }}</td>
                  <td>{{ exam.C }}</td>
                  <td>
                    <b-badge v-if="exam.D" style="width:46px" variant="success">Dogru</b-badge>
                    <b-badge v-if="exam.Y" style="width:46px" variant="danger">Yanlis</b-badge>
                    <b-badge v-if="exam.B" style="width:46px" variant="warning">Bos</b-badge>
                  </td>
                </tr>
              </table>
            </template>
          </div>
        </template>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "gains-tree-detail",
  props: {
    lessonTitle: {
      type: String,
      required: false
    },
    treeData: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getGainDetailId", "getExamList"])
  }
};
</script>

<style></style>
