import Vue from "vue";

import { mapState, mapGetters, mapActions } from "vuex";

import { LIST, SET, RESET } from "@/store/form.module";

export const EventBus = new Vue();

const mixin = {
  created() {
    const func = this.onFormSubmit;
    if (!func) return;
    EventBus.$on("ON_SUBMIT", func);
  },
  methods: {
    ...mapActions([LIST, SET, RESET])
  },
  computed: {
    ...mapState({
      donems: state => state.form.donems,
      corps: state => state.form.corps,
      branches: state => state.form.branches,
      classes: state => state.form.classes,
      students: state => state.form.students,
      examTypes: state => state.form.examTypes,
      exams: state => state.form.exams
    }),
    ...mapGetters(["form"]),
    listForm() {
      return this[LIST];
    },
    setForm() {
      return this[SET];
    },
    resetForm() {
      return this[RESET];
    }
  }
};

export default mixin;
