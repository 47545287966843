<template>
  <img :src="src" alt="icon" :height="height" />
</template>

<script>
export default {
  name: "icon",
  props: {
    height: {
      type: Number,
      default: 50
    },
    src: {
      type: String,
      required: true
    }
  }
};
</script>
