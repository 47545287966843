import { isInt } from "@/common/utils";

class BarChartModel {
  constructor(data) {
    /**
     * Eg. data parameter:
     [
       {
          title: "Türkçe",
          dataset: [
            {
              label: "BDS-1",
              data: 5
            },
            {
              label: "BDS-2",
              data: 5
            },
            {
              label: "Genel",
              data: 5
            },
            ...
          ]
        },
     ...
     ]
     */
    this.data = data;
  }

  toFixed(data) {
    return data.map(n => {
      if (isInt(n)) return n;

      try {
        return n.toFixed(2);
      } catch (e) {
        return n;
      }
    });
  }

  getDataByDatasetLabel(label) {
    const data = this.data.map(d => {
      const dataset = d.dataset.find(dataset => dataset.label === label);
      return dataset.data;
    });

    return this.toFixed(data);
  }

  chartData() {
    const [firstData = { dataset: [] }] = this.data;
    const labels = this.data.map(d => d.title); // ["Türkçe", "Mat", "Fen", ...]
    const datasetLabels = firstData.dataset.map(dataset => dataset.label); // [bds-1, bds-2, ...]
    const datasets = datasetLabels.map(datasetLabel => {
      const data = this.getDataByDatasetLabel(datasetLabel);
      return { label: datasetLabel, data };
    });

    return {
      labels,
      datasets
    };
  }
}

export default BarChartModel;
