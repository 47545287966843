class GainsTableModel {
  constructor({ lessonName, correct, wrong, empty, net, gains }) {
    this.lessonName = lessonName;
    this.correct = correct;
    this.wrong = wrong;
    this.empty = empty;
    this.net = net;
    this.gains = gains;
  }

  summary() {
    return {
      correct: this.correct,
      wrong: this.wrong,
      empty: this.empty,
      net: this.net
    };
  }

  chartData() {
    return {
      lesson: this.lessonName,
      summary: this.summary(),
      gains: this.gains
    };
  }
}

export default GainsTableModel;
