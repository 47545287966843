<template>
  <div>
    <LineBar />
    <ReportHeader
      :hide-logo="hideLogo"
      :leftTitle="headerLeftTitle"
      :leftSubtitle="headerLeftSubTitle"
      :rightSubtitle="headerRightTitle"
    ></ReportHeader>
    <slot></slot>
    <slot name="legend"></slot>
    <LineBar variant="second" />
  </div>
</template>

<script>
import LineBar from "@/components/line.component.vue";
import ReportHeader from "@/components/reports/header.component.vue";

export default {
  name: "base-report-template",
  components: {
    LineBar,
    ReportHeader
  },
  props: {
    headerLeftTitle: {
      type: String,
      required: true
    },
    headerLeftSubTitle: {
      type: String,
      default: null
    },
    headerRightTitle: {
      type: String,
      default: null
    },
    hideLogo: {
      type: Boolean,
      default: false
    }
  }
};
</script>
