/*
Parse and format API for student level reports for TYT
 */
import * as _ from "lodash";

import { SinavAPI } from "@/common/api";

function parseNetsByLesson(dataByLesson, generalData) {
  const lessons = [
    "Türkçe",
    "Tarih",
    "Coğrafya",
    "Felsefe",
    "Din Kültürü",
    "Matematik",
    "Geometri",
    "Fizik",
    "Kimya",
    "Biyoloji"
  ];

  return lessons.map((lessonName, index) => {
    const i = index + 1;
    const correctKey = `BL${i}D`;
    const wrongKey = `BL${i}Y`;
    const emptyKey = `BL${i}B`;
    const netKey = `BL${i}N`;
    const generalNetKey = `BL${i}GN`;
    const percentKey = `BL${i}Z`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalData[generalNetKey],
      correctnessPercent: dataByLesson[percentKey],
      total: dataByLesson[correctKey] + dataByLesson[wrongKey] + dataByLesson[emptyKey]
    };
  });
}

function parseMainNets(dataByLesson, generalData) {
  const lessons = [
    {
      lesson: "Türkçe",
      netKey: "TRK_",
      generalNetKey: "TRK_"
    },
    {
      lesson: "Sosyal Bilimler",
      netKey: "SOS_",
      generalNetKey: "SOS_"
    },
    {
      lesson: "Matematik",
      netKey: "MAT_",
      generalNetKey: "MAT"
    },
    {
      lesson: "Fen Bilimleri",
      netKey: "FEN_",
      generalNetKey: "FEN"
    }
  ];

  return lessons.map(lesson => {
    const { lesson: lessonName, netKey: _netKey, generalNetKey: _generalNetKey } = lesson;
    return {
      lesson: lessonName,
      correct: dataByLesson[`${_netKey}D`],
      wrong: dataByLesson[`${_netKey}Y`],
      empty: dataByLesson[`${_netKey}B`],
      net: dataByLesson[`${_netKey}N`],
      averageNet: generalData[`${_generalNetKey}GN`],
      correctnessPercent: dataByLesson[`${_netKey}Z`],
      meta: { ...dataByLesson, ...generalData }
    };
  });
}

function parseAttendance(dataByLesson, generalData) {
  return {
    class: dataByLesson.KATILIMSINIF,
    corp: dataByLesson.KATILIMOKUL,
    general: generalData.SINAV_KATILIM
  };
}

function parseRanks(dataByLesson) {
  return {
    class: dataByLesson.TYTSIS,
    corp: dataByLesson.TYTOKLS,
    general: dataByLesson.TYTGES
  };
}

function chunkGains(gains) {
  return _.chunk(gains, 20);
}

function parseNetsFromGainsTree(studentData, averageData) {
  return {
    correct: studentData.TD,
    wrong: studentData.TY,
    empty: studentData.TB,
    correctnessPercent: studentData.TZ,
    net: studentData.TN,
    averageNet: averageData.TGN
  };
}

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue.D;
        const total = accumulator.total + currentValue.SS;
        const percent = Math.floor((100 * correct) / total);

        return {
          id: currentValue[idKey],
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue.Y,
          empty: accumulator.empty + currentValue.B,
          percent,
          unitId: currentValue.ID_UNITE,
          topicId: currentValue.ID_KONU,
          gainId: currentValue.ID_KAZANIM
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function generateTreeData(gains) {
  const gainsMappedByLesson = _.groupBy(gains, "DERSAD");

  return Object.keys(gainsMappedByLesson).map(lessonName => {
    const gainsBelongToLesson = gains.filter(gain => gain.DERSAD === lessonName);
    let units = _.groupBy(gainsBelongToLesson, "ID_UNITE");
    let topics = _.groupBy(gainsBelongToLesson, "ID_KONU");
    let _gains = _.groupBy(gainsBelongToLesson, "ID_KAZANIM");

    units = mergeGainsBy({
      data: units,
      idKey: "ID_UNITE",
      titleKey: "UNITE"
    });
    topics = mergeGainsBy({
      data: topics,
      idKey: "ID_KONU",
      titleKey: "KONU"
    });
    _gains = mergeGainsBy({
      data: _gains,
      idKey: "ID_KAZANIM",
      titleKey: "KAZANIM"
    });

    topics = topics.map(topic => {
      return {
        ...topic,
        gains: _gains.filter(gain => gain.topicId === topic.id)
      };
    });
    units = units.map(unit => {
      return {
        ...unit,
        topics: topics.filter(topic => topic.unitId === unit.id)
      };
    });

    const total = units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
    const correct = units.reduce(
      (accumulator, currentValue) => accumulator + currentValue.correct,
      0
    );
    const correctnessPercent = Math.floor((100 * correct) / total);

    return {
      lesson: lessonName,
      total,
      correct,
      wrong: units.reduce((accumulator, currentValue) => accumulator + currentValue.wrong, 0),
      empty: units.reduce((accumulator, currentValue) => accumulator + currentValue.empty, 0),
      correctnessPercent,
      units
    };
  });
}

function groupTreeDataByMainLesson(treeDataByLesson) {
  const mainLessons = [
    {
      lesson: "Türkçe",
      subLessons: ["Türkçe"]
    },
    {
      lesson: "Sosyal Bilimler",
      subLessons: ["Felsefe", "Din Kültürü", "Coğrafya", "Tarih"]
    },
    {
      lesson: "Matematik",
      subLessons: ["Matematik", "Geometri"]
    },
    {
      lesson: "Fen Bilimleri",
      subLessons: ["Fizik", "Kimya", "Biyoloji"]
    }
  ];

  return mainLessons.map(mainLesson => {
    return {
      lesson: mainLesson.lesson,
      subLessons: treeDataByLesson.filter(
        treeData => mainLesson.subLessons.indexOf(treeData.lesson) !== -1
      )
    };
  });
}

function mergeTreeDataAndAverageNets(treeDataByMainLesson, averageNetsByMainLesson) {
  return treeDataByMainLesson.map(treeData => {
    const nets = averageNetsByMainLesson.find(
      netByLesson => netByLesson.lesson === treeData.lesson
    );

    return {
      ...treeData,
      ...nets
    };
  });
}

function parseAverageScoresFromComparison(studentDataByExam, averageDataByExam) {
  return studentDataByExam.map(studentData => {
    const averageData = averageDataByExam.find(
      averageData => averageData.ID_SINAVBILGI === studentData.ID_SINAVBILGI
    );
    return {
      exam: studentData.SINAVAD,
      score: studentData.TYTPUAN,
      averageScore: averageData.PUAN_GN
    };
  });
}

function parseMainLessonAverageNetsFromComparison({
  studentDataByExam,
  averageDataByExam,
  studentAverageScores,
  averageScoreData
}) {
  const mainLessons = [
    {
      lesson: "Türkçe",
      studentNetKeys: ["TRKN"], // sınav bazlı
      averageNetKeys: ["TRK_SNVORTN"], // sınav bazlı
      averageStudentKeys: ["TRK_N"], // tüm sınavların ortalaması
      generalAverageNetKeys: ["TRK_GN"] // tüm sınavların ortalaması
    },
    {
      lesson: "Matematik",
      studentNetKeys: ["MATN", "GEON"],
      averageNetKeys: ["MAT_SNVORTN", "GEO_SNVORTN"],
      averageStudentKeys: [],
      generalAverageNetKeys: ["MAT_GN", "GEO_GN"]
    },
    {
      lesson: "Sosyal Bilimler",
      studentNetKeys: ["TARN", "FELN", "COGN"],
      averageNetKeys: ["TAR_SNVORTN", "FEL_SNVORTN", "COG_SNVORTN"],
      averageStudentKeys: ["TAR_N", "FEL_N", "COG_N"],
      generalAverageNetKeys: ["TAR_GN", "FEL_GN", "COG_GN"]
    },
    {
      lesson: "Fen Bilimleri",
      studentNetKeys: ["FIZN", "KIMN", "BIYN"],
      averageNetKeys: ["FIZ_SNVORTN", "KIM_SNVORTN", "BIY_SNVORTN"],
      averageStudentKeys: ["FIZ_N", "KIM_N", "BIY_N"],
      generalAverageNetKeys: ["FIZ_GN", "KIM_GN", "BIY_GN"]
    }
  ];

  return mainLessons.map(lesson => {
    const {
      lesson: lessonName,
      studentNetKeys,
      averageNetKeys,
      averageStudentKeys,
      generalAverageNetKeys
    } = lesson;

    let averageNet = 0;
    averageStudentKeys.forEach(key => {
      const val = studentAverageScores[key] || 0;
      averageNet += val;
    });

    let generalAverageNet = 0;
    generalAverageNetKeys.forEach(key => {
      const val = averageScoreData[key] || 0;
      generalAverageNet += val;
    });

    return {
      lesson: lessonName,
      net: averageNet,
      averageNet: generalAverageNet,
      exams: studentDataByExam.map(studentData => {
        const averageData = averageDataByExam.find(
          e => e.ID_SINAVBILGI === studentData.ID_SINAVBILGI
        );

        let net = 0;
        studentNetKeys.forEach(key => {
          const val = studentData[key] || 0;
          net += val;
        });

        let averageNet = 0;
        averageNetKeys.forEach(key => {
          const val = averageData[key] || 0;
          averageNet += val;
        });

        return {
          exam: studentData.SINAVAD,
          net,
          averageNet
        };
      })
    };
  });
}

function parseGeneralNets(dataByLesson, generalNets) {
  return {
    correct: dataByLesson.TD,
    wrong: dataByLesson.TY,
    empty: dataByLesson.TB,
    net: dataByLesson.TN,
    correctnessPercent: dataByLesson.TZ,
    averageNet: generalNets.GN
  };
}

function parseMainNetsFromGainsTree(studentAverageData, averageData) {
  return [
    {
      lesson: "Türkçe",
      studentNet: studentAverageData.TRK_N,
      averageNet: averageData.TRK_GN
    },
    {
      lesson: "Sosyal Bilimler",
      studentNet: studentAverageData.SOS_N,
      averageNet: averageData.TAR_GN
    },
    {
      lesson: "Matematik",
      studentNet: studentAverageData.MAT_N,
      averageNet: averageData.MAT_GN
    },
    {
      lesson: "Fen Bilimleri",
      studentNet: studentAverageData.FEN_N,
      averageNet: averageData.FEN_GN
    }
  ];
}

function parseNetsByLessonFromComparison({
  studentDataByExam,
  studentAverageScores,
  averageDataByExam,
  averageScoreData
}) {
  const lessons = [
    {
      lesson: "Türkçe",
      studentNetKey: "TRKN",
      studentAverageNetKey: "TRK_SNVORTN",
      averageNetKey: "TRK_N",
      generalAverageNetKey: "TRK_GN"
    },
    {
      lesson: "Tarih",
      studentNetKey: "TARN",
      studentAverageNetKey: "TAR_SNVORTN",
      averageNetKey: "TAR_N",
      generalAverageNetKey: "TAR_GN"
    },
    {
      lesson: "Coğrafya",
      studentNetKey: "COGN",
      studentAverageNetKey: "COG_SNVORTN",
      averageNetKey: "COG_N",
      generalAverageNetKey: "COG_GN"
    },
    {
      lesson: "Matematik",
      studentNetKey: "MATN",
      studentAverageNetKey: "MAT_SNVORTN",
      averageNetKey: "MAT_N",
      generalAverageNetKey: "MAT_GN"
    },
    {
      lesson: "Geometri",
      studentNetKey: "GEON",
      studentAverageNetKey: "GEO_SNVORTN",
      averageNetKey: "GEO_N",
      generalAverageNetKey: "GEO_GN"
    },
    {
      lesson: "Felsefe",
      studentNetKey: "FELN",
      studentAverageNetKey: "FEL_SNVORTN",
      averageNetKey: "FEL_N",
      generalAverageNetKey: "FEL_GN"
    },
    {
      lesson: "Fizik",
      studentNetKey: "FIZN",
      studentAverageNetKey: "FIZ_SNVORTN",
      averageNetKey: "FIZ_N",
      generalAverageNetKey: "FIZ_GN"
    },
    {
      lesson: "Kimya",
      studentNetKey: "KIMN",
      studentAverageNetKey: "KIM_SNVORTN",
      averageNetKey: "KIM_N",
      generalAverageNetKey: "KIM_GN"
    },
    {
      lesson: "Biyoloji",
      studentNetKey: "BIYN",
      studentAverageNetKey: "BIY_SNVORTN",
      averageNetKey: "BIY_N",
      generalAverageNetKey: "BIY_GN"
    }
  ];

  return lessons.map(lesson => {
    const {
      studentNetKey,
      averageNetKey,
      studentAverageNetKey,
      generalAverageNetKey,
      lesson: lessonName
    } = lesson;

    const exams = studentDataByExam.map(studentData => {
      const averageData = averageDataByExam.find(
        avg => avg.ID_SINAVBILGI === studentData.ID_SINAVBILGI
      );
      return {
        exam: studentData.SINAVAD,
        studentNet: studentData[studentNetKey],
        averageNet: averageData[studentAverageNetKey]
      };
    });

    const average = {
      studentNet: studentAverageScores[averageNetKey],
      generalNet: averageScoreData[generalAverageNetKey]
    };

    return {
      lesson: lessonName,
      exams,
      average
    };
  });
}

function parsePercentAndScoreFromComparison(studentDataByExam) {
  const [highest = {}] = studentDataByExam
    .sort((a, b) => (a.TYTPUAN > b.TYTPUAN ? 1 : -1))
    .reverse();

  return {
    score: highest.TYTPUAN || 0,
    percent: highest.TYTZ || 0
  };
}

function getAnswer(studentAnswerBooks, lesson) {
  const book = studentAnswerBooks.find(b => b.KITAPCIK === lesson.KITAPCIK) || {};
  const index = lesson.RN - 1;
  const answerList = book[`BL${lesson.BOLUM}A`];
  return answerList[index];
}

export default {
  // On Karne
  async followUpReport({ examId, userId }) {
    const [dataByLesson] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 1);
    const [generalNetsData] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 3);
    let [prevExam = {}] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 2);
    const netsByLesson = parseNetsByLesson(dataByLesson, generalNetsData);
    const mainNetsByLesson = parseMainNets(dataByLesson, generalNetsData);
    const generalNets = parseGeneralNets(dataByLesson, generalNetsData);
    const attendance = parseAttendance(dataByLesson, generalNetsData);
    const ranks = parseRanks(dataByLesson, generalNetsData);
    const prevExamMainNetsByLesson =
      prevExam.AD === "Önceki Sinav Bilgisi Yok" ? null : parseMainNets(prevExam, {});
    return {
      netsByLesson,
      mainNetsByLesson,
      generalNets,
      score: dataByLesson.TYTPUAN,
      averageScore: generalNetsData.TYTGEO,
      futureScore: dataByLesson.TYTPUAN_YANLISSIZ,
      percent: (dataByLesson.TYTGES / generalNetsData.SINAV_KATILIM) * 100,
      examName: dataByLesson.SINAVADI || generalNetsData.SINAVAD,
      session: dataByLesson.KITAPCIK,
      attendance,
      ranks,
      prevExamMainNetsByLesson
    };
  },
  // Arka Karne
  async gains({ examId, userId }) {
    const [dataByLesson] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 1);
    const [generalNets] = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 3);
    const gains = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 6);
    const studentAnswerBooks = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 4);
    const questionStatistics = await SinavAPI.OgrenciSinavTuruneGoreSinavlari(userId, examId, 7);
    const mainNetsByLesson = parseMainNets(dataByLesson, generalNets);
    const gainsByMainLesson = mainNetsByLesson.map(mainNetByLesson => {
      const { lesson } = mainNetByLesson;
      let subLessons;
      switch (lesson) {
        case "Türkçe":
          subLessons = ["Türkçe"];
          break;
        case "Sosyal Bilimler":
          subLessons = ["Felsefe", "Din Kültürü", "Cografya", "Tarih"];
          break;
        case "Matematik":
          subLessons = ["Matematik", "Geometri"];
          break;
        case "Fen Bilimleri":
          subLessons = ["Fizik", "Kimya", "Biyoloji"];
          break;
      }

      subLessons = gains.filter(gain => subLessons.indexOf(gain.DERSAD) > -1);
      subLessons = subLessons.map((lesson, i) => {
        const YO = questionStatistics.filter(e=> e.BOLUMNO == lesson.BOLUM && e.SN == lesson.RN)[0].Y_D
        return {
          YO,
          ANSWER: getAnswer(studentAnswerBooks, lesson),
          ...lesson,
          RN: i + 1
        };
      });
      subLessons = _.groupBy(subLessons, "ID_DERS");
      subLessons = Object.keys(subLessons).map(subLessonId => {
        return {
          lesson: subLessons[subLessonId][0].DERSAD,
          gains: subLessons[subLessonId]
        };
      });

      const left = [];
      const right = [];
      subLessons.forEach(subLesson => {
        // dersleri iki parçaya böl (left, right)
        // her bir parçanın içinde yer alan derslerin eleman sayısı maks 20 olmalı
        // örn: [[1, 2, ..., 15], [1, 2, 3, 4, 5]]
        // yukarıdaki dizide ilk dersin 15 elemanı var. İkinci dersin 5 elemanı olmalı
        const { gains, lesson } = subLesson;
        if (gains.length > 20) {
          const [firstChunk, secondChunk] = chunkGains(gains);
          left.push({ lesson, gains: firstChunk });
          right.push({ lesson, gains: secondChunk });
          return;
        }

        let leftTotalLen = 0;
        left.forEach(l => {
          leftTotalLen += l.gains.length;
        });
        if (leftTotalLen < 20) {
          if (gains.length < 20 - leftTotalLen) {
            left.push(subLesson);
          } else {
            const need = gains.slice(0, 20 - leftTotalLen);
            const remaining = gains.slice(need.length, gains.length);
            left.push({ lesson, gains: need });
            if (remaining.length) {
              right.push({ lesson, gains: remaining });
            }
          }
        } else {
          right.push(subLesson);
        }
      });

      return {
        ...mainNetByLesson,
        left,
        right
      };
    });

    return { gainsByMainLesson };
  },
  // Kazanim Agaci
  async gainsTree({ examIdList, userId }) {
    const [studentAverageData] = await SinavAPI.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 2
    });
    let [averageData] = await SinavAPI.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 3
    });
    const allGains = await SinavAPI.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 7
    });

    const nets = parseNetsFromGainsTree(studentAverageData, averageData);
    const mainNets = parseMainNetsFromGainsTree(studentAverageData, averageData);
    const score = studentAverageData.TYTPUAN;

    const treeDataByLesson = generateTreeData(allGains);
    let treeDataByMainLesson = groupTreeDataByMainLesson(treeDataByLesson);
    treeDataByMainLesson = mergeTreeDataAndAverageNets(treeDataByMainLesson, mainNets);

    treeDataByMainLesson = treeDataByMainLesson.map(lesson => {
      const subLessons = lesson.subLessons || [];
      const correct = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.correct,
        0
      );
      const wrong = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.wrong,
        0
      );
      const empty = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.empty,
        0
      );
      const total = correct + wrong + empty;
      const correctnessPercent = (correct * 100) / total;

      return {
        ...lesson,
        correct,
        wrong,
        empty,
        correctnessPercent
      };
    });

    return {
      nets,
      score,
      treeDataByMainLesson
    };
  },

  async comparison({ userId, examIdList }) {
    const { data: studentDataByExam } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 1,
      section: 1
    });

    let { data: studentAverageScores } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 1,
      section: 2
    });
    [studentAverageScores] = studentAverageScores;

    const { data: averageDataByExam } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 1,
      section: 3
    });

    let { data: averageScoreData } = await SinavAPI.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 1,
      section: 4
    });
    [averageScoreData] = averageScoreData;

    const score = studentAverageScores.PUAN_N;
    const net = studentAverageScores.TPLM_N;
    const averageScore = averageScoreData.ORT_GENELSINAVPUAN;
    const averageNet = averageScoreData.TPLM_GN;

    const percentAndScore = parsePercentAndScoreFromComparison(studentDataByExam);

    const averageScoresByExam = parseAverageScoresFromComparison(
      studentDataByExam,
      averageDataByExam
    );
    const netsByMainLessons = parseMainLessonAverageNetsFromComparison({
      studentDataByExam,
      averageDataByExam,
      studentAverageScores,
      averageScoreData
    });

    const netsByLesson = parseNetsByLessonFromComparison({
      studentDataByExam,
      studentAverageScores,
      averageDataByExam,
      averageScoreData
    });

    return {
      score,
      averageScore,
      net,
      averageNet,
      averageScoresByExam,
      netsByMainLessons,
      percentAndScore,
      netsByLesson
    };
  }
};
