<template>
  <b-jumbotron>
    <div>
      <slot></slot>
    </div>
    <img :src="url" v-if="background" />
  </b-jumbotron>
</template>

<script>
import info from "@/assets/media/icons/info.svg";

export default {
  name: "jumbotron",
  props: {
    background: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      return info;
    }
  }
};
</script>
<style lang="scss" scoped>
.jumbotron {
  display: inline-flex;
  font-family: "GothamNarrowBook";
  color: rgb(128, 129, 132);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 9px;
  background-color: #edeeef;
  border: 3px solid #e1e2e3;
  border-radius: 10px;
  padding: 10px 13px;
  line-height: 1.3;
  b {
    font-family: "GothamNarrowBold";
    font-weight: 400;
    font-size: 13px;
  }
  p {
    margin-bottom: 4px;
  }
  img {
    margin-left: 13px;
    margin-bottom: 13px;
  }
}
</style>
