<template>
  <div class="label" :style="backgroundStyle">
    <span :class="textLeftPosition" :style="textLeftStyle">
      <template v-if="iconProps"> <Icon v-bind="iconProps"></Icon> <span>|</span> </template>
      {{ left }}
    </span>
    <span :class="textRightPosition" :style="textRightStyle">
      {{ right }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Icon from "@/components/icons/icon.component.vue";

export default {
  name: "karnem-label",
  props: {
    rightSidePercent: {
      type: Number,
      default: 40
    },
    left: {
      type: String,
      default: ""
    },
    leftPosition: {
      type: String,
      default: "left",
      validator(value) {
        // The value must match one of these strings
        return ["left", "center", "right"].indexOf(value) !== -1;
      }
    },
    leftStyle: {
      type: Object,
      default: () => {}
    },
    right: {
      type: [Number, String],
      default: ""
    },
    rightPosition: {
      type: String,
      default: "right",
      validator(value) {
        // The value must match one of these strings
        return ["left", "center", "right"].indexOf(value) !== -1;
      }
    },
    rightStyle: {
      type: Object,
      default: () => {}
    },
    iconProps: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: "small",
      validator(value) {
        // The value must match one of these strings
        return ["small", "medium", "large"].indexOf(value) !== -1;
      }
    },
    color: {
      type: String,
      default: "gray",
      validator(value) {
        // The value must match one of these strings
        return ["gray", "blue", "orange"].indexOf(value) !== -1;
      }
    }
  },
  components: {
    Icon
  },
  computed: {
    ...mapGetters(["theme"]),
    textLeftPosition() {
      return `label-${this.leftPosition}`;
    },
    textRightPosition() {
      return `label-${this.rightPosition}`;
    },
    textLeftStyle() {
      return {
        color: "#ffffff",
        width: "60%",
        fontSize: "0.9em",
        ...this.leftStyle
      };
    },
    textRightStyle() {
      return {
        color: this.theme.colors.GRAY,
        width: "40%",
        fontFamily: "GothamNarrowBlack",
        ...this.rightStyle
      };
    },
    backgroundColor() {
      let left = [128, 130, 133, 1];
      let hexLeft = "808285";
      const right = [230, 231, 232, 1];
      const hexRight = "#e6e7e8";

      if (this.color === "orange") {
        left = [245, 130, 32, 1];
        hexLeft = "f58220";
      } else if (this.color === "blue") {
        left = [0, 174, 239, 1];
        hexLeft = "00aeef";
      }

      return {
        left: `rgba(${left.join(",")})`,
        right: `rgba(${right.join(",")})`,
        hexLeft: `#${hexLeft}`,
        hexRight
      };
    },
    backgroundStyle() {
      const { backgroundColor, size } = this;
      const percent = this.rightSidePercent;
      let fontSize;
      let fontFamily;

      if (size === "small") {
        fontSize = 1.2;
        fontFamily = "GothamCondensedBook";
      } else if (size === "medium") {
        fontSize = 1.2;
        fontFamily = "GothamNarrowBlack";
      } else {
        fontSize = 2;
        fontFamily = "GothamNarrowBlack";
      }

      return {
        fontSize: `${fontSize}rem`,
        fontFamily,
        background: `linear-gradient(-45deg, ${backgroundColor.right} ${percent}%, ${backgroundColor.left} ${percent}%)`,
        filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="${backgroundColor.right}",endColorstr="${backgroundColor.left}",GradientType=1)`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  display: inline-flex;
  justify-content: space-between;
  text-transform: uppercase;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  line-height: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  > span {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:last-of-type {
      justify-content: center;
      padding-left: 5%;
    }
    span {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  &-left {
    justify-content: flex-start;
    text-align: left;
  }
  &-right {
    justify-content: flex-end;
    text-align: right;
  }
  &-center {
    justify-content: center;
    text-align: center;
  }
}
</style>
