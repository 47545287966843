/*
Parse and format API for class level reports
 */
import * as _ from "lodash";
import md5 from "crypto-js/md5";

import { SinavAPI } from "@/common/api";

function parseNetsByLesson(dataByLesson, generalData) {
  const lessons = [
    "Türkçe",
    "Matematik",
    "Fen Bilimleri",
    "Sosyal Bilgiler",
    "Yabancı Dil",
    "Din Kültürü"
  ];

  return lessons.map((lessonName, index) => {
    const i = index + 1;
    const correctKey = `BL${i}D`;
    const wrongKey = `BL${i}Y`;
    const emptyKey = `BL${i}B`;
    const netKey = `BL${i}N`;
    const generalNetKey = `BL${i}NG`;
    const percentKey = `BL${i}Z`;
    const rankKey = `BL${i}OKS`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalData[generalNetKey],
      correctnessPercent: dataByLesson[percentKey],
      rank: dataByLesson[rankKey],
      meta: { ...dataByLesson, ...generalData }
    };
  });
}

function parseGeneralNetData(dataByLesson, generalData) {
  const total = dataByLesson.D_TOPLAM + dataByLesson.Y_TOPLAM + dataByLesson.B_TOPLAM;
  const correctnessPercent = Math.floor((100 * dataByLesson.D_TOPLAM) / total);
  return {
    correct: dataByLesson.D_TOPLAM,
    wrong: dataByLesson.Y_TOPLAM,
    empty: dataByLesson.B_TOPLAM,
    net: dataByLesson.N_TOPLAM,
    averageNet: generalData.GNLSNVNET,
    rank: dataByLesson.GNLPUAN_GES,
    correctnessPercent
  };
}

async function getPrevExamData({ examId, classId }) {
  let { data: examData } = await SinavAPI.SinifSinavAnaliziTumVeriler({
    examId,
    classId,
    action: 2
  });

  if (!examData) return [];
  [examData = {}] = examData;

  let { data: generalDataByLesson } = await SinavAPI.SinifSinavAnaliziTumVeriler({
    examId: examData.ID_SINAVBILGI,
    classId,
    action: 3
  });
  [generalDataByLesson = {}] = generalDataByLesson;

  return parseNetsByLesson(examData, generalDataByLesson);
}

function sectionNoToLessonName(gains) {
  const groupedGains = _.groupBy(gains, "BOLUMNO");
  const lessonSections = {
    1: "Türkçe",
    2: "Matematik",
    3: "Fen Bilimleri",
    4: "Sosyal Bilgiler",
    5: "Yabancı Dil",
    6: "Din Kültürü"
  };

  return Object.keys(groupedGains).map(sectionNo => {
    const lesson = lessonSections[sectionNo];
    return {
      lesson,
      gains: groupedGains[sectionNo]
    };
  });
}

function mergeGainsAndNets(gains, nets) {
  return gains.map(gain => {
    const netData = nets.find(net => net.lesson === gain.lesson);
    return {
      ...gain,
      ...netData
    };
  });
}

function parseNetsByLessonFromGains(data) {
  const pairs = {
    TRK: "Türkçe",
    MAT: "Matematik",
    FEN: "Fen Bilimleri",
    SOS: "Sosyal Bilgiler",
    ING: "Yabancı Dil",
    DIN: "Din Kültürü"
  };

  return Object.keys(pairs).map(key => {
    const correctKey = `${key}_D`;
    const wrongKey = `${key}_Y`;
    const emptyKey = `${key}_B`;
    const classNetKey = `${key}_N`;
    const correctnessPercentKey = `${key}_Z`;
    const averageNetKey = `${key}_SNVGNLN`;

    return {
      lesson: pairs[key],
      correct: data[correctKey],
      wrong: data[wrongKey],
      empty: data[emptyKey],
      classNet: data[classNetKey],
      correctnessPercent: data[correctnessPercentKey],
      averageNet: data[averageNetKey]
    };
  });
}

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    const correctKey = "DOĞRU";
    const totalQuestionKey = "SORU SAYISI";
    const wrongKey = "YANLIŞ";
    const emptyKey = "BOŞ";
    const unitKey = "ÜNİTE";
    const topicKey = "KONU";
    const gainKey = "KAZANIM";

    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue[correctKey];
        const total = accumulator.total + currentValue[totalQuestionKey];
        const percent = Math.floor((100 * correct) / total);

        const id = `id_${currentValue[idKey]}`;
        const unitId = `unit_${currentValue[unitKey]}`;
        const topicId = `topic_${currentValue[topicKey]}`;
        const gainId = `gain_${currentValue[gainKey]}`;

        return {
          _id: md5(currentValue[idKey]).toString(),
          id: md5(id).toString(),
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue[wrongKey],
          empty: accumulator.empty + currentValue[emptyKey],
          percent,
          _unitId: md5(currentValue[unitKey]).toString(),
          unitId: md5(unitId).toString(),
          _topicId: md5(currentValue[unitKey]).toString(),
          topicId: md5(topicId).toString(),
          _gainId: md5(currentValue[gainKey]).toString(),
          gainId: md5(gainId).toString()
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function generateTreeData(netsByLesson, gainsByLesson) {
  return netsByLesson.map(netByLesson => {
    const lessonName = netByLesson.lesson === "Yabancı Dil" ? "İngilizce" : netByLesson.lesson;
    const gainsBelongToLesson = gainsByLesson.filter(
      gain => gain.DERS === lessonName && gain.KAZANIM
    );

    let units = _.groupBy(gainsBelongToLesson, "ÜNİTE");
    let topics = _.groupBy(gainsBelongToLesson, "KONU");
    let gains = _.groupBy(gainsBelongToLesson, "KAZANIM");

    units = mergeGainsBy({
      data: units,
      idKey: "ÜNİTE",
      titleKey: "ÜNİTE"
    });
    topics = mergeGainsBy({
      data: topics,
      idKey: "KONU",
      titleKey: "KONU"
    });
    gains = mergeGainsBy({
      data: gains,
      idKey: "KAZANIM",
      titleKey: "KAZANIM"
    });

    topics = topics.map(topic => {
      return {
        ...topic,
        gains: gains.filter(gain => gain._topicId === topic._topicId)
      };
    });

    units = units.map(unit => {
      return {
        ...unit,
        topics: topics.filter(topic => topic._unitId === unit._id)
      };
    });

    return {
      ...netByLesson,
      percent: netByLesson.correctnessPercent, // quick fix for gain tree
      units,
      total: units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)
    };
  });
}

function parseAllCorrect(data) {
  const lessons = [
    { key: "TRKTAM", lesson: "Türkçe" },
    { key: "MATTAM", lesson: "Matematik" },
    { key: "FENTAM", lesson: "Fen Bilimleri" },
    { key: "SOSTAM", lesson: "Sosyal Bilgiler" },
    { key: "INGTAM", lesson: "Yabancı Dil" },
    { key: "DINTAM", lesson: "Din Kültürü" }
  ];

  const byLesson = lessons.map(lesson => {
    return {
      lesson: lesson.lesson,
      count: data[lesson.key]
    };
  });

  return {
    all: data.TAM,
    byLesson
  };
}

export default {
  async frontExamReport({ examId, classId }) {
    /**
     * islem 1: ders bazlı veriler
     * islem 2: bir önceki sınav ile kıyaslama
     * islem 3: genel net bilgileri
     */
    let { data: dataByLesson } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 1
    });
    [dataByLesson = {}] = dataByLesson;

    let { data: generalDataByLesson } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 3
    });
    [generalDataByLesson = {}] = generalDataByLesson;

    let { data: attendanceAndCountData } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 4
    });
    [attendanceAndCountData = {}] = attendanceAndCountData;

    let { data: allCorrectData } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 5
    });
    [allCorrectData = {}] = allCorrectData;

    const allCorrect = parseAllCorrect(allCorrectData);
    const prevExam = await getPrevExamData({ examId, classId });

    return {
      netsByLesson: parseNetsByLesson(dataByLesson, generalDataByLesson),
      generalNetData: parseGeneralNetData(dataByLesson, generalDataByLesson),
      averageScore: generalDataByLesson.GNLPUAN700GEO,
      corpScore: dataByLesson.GNLPUAN_SNV,
      corpScorePercent: dataByLesson.GNLPUAN700_YD,
      attendance: {
        generalAttendance: generalDataByLesson.SINAV_KATILIM,
        classAttendance: dataByLesson.OGRSAY,
        corpAttendance: attendanceAndCountData.OKULSINIFKATILIM,
        corpCount: generalDataByLesson.OKULSAYISI,
        classCount: generalDataByLesson.SINIFSAYISI,
        corpRank: dataByLesson.GNLPUAN_OKS
      },
      prevExam,
      allCorrect
    };
  },

  async backExamReport({ examId, classId, examTypeId }) {
    let { data: gains } = await SinavAPI.SinifSinavAnaliziTumVerilerArka({
      examId,
      classId,
      examTypeId,
      action: 1 // 1 sbs, 2 tyt, 3 ayt
    });

    let { data: netsByLesson } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 1
    });
    [netsByLesson = {}] = netsByLesson;

    const attendance = netsByLesson.OGRSAY;
    netsByLesson = parseNetsByLesson(netsByLesson, {});
    gains = sectionNoToLessonName(gains);

    const gainsWithNets = mergeGainsAndNets(gains, netsByLesson);
    return {
      gains: gainsWithNets,
      attendance
    };
  },

  async gainsTree({ examIdList, classId }) {
    let { data: generalNets } = await SinavAPI.SinifKazanimKarnesiTumVeriler({
      examIdList,
      classId,
      action: 3
    });
    [generalNets = {}] = generalNets;

    let { data: gainsByLesson } = await SinavAPI.SinifKazanimKarnesiTumVeriler({
      examIdList,
      classId,
      action: 1
    });

    const netsByLesson = parseNetsByLessonFromGains(generalNets);
    const treeData = generateTreeData(netsByLesson, gainsByLesson);
    const total = generalNets.TPLMD + generalNets.TPLMY + generalNets.TPLMB;

    return {
      general: {
        correct: generalNets.TPLMD,
        wrong: generalNets.TPLMY,
        empty: generalNets.TPLMB,
        correctnessPercent: Math.floor((100 * generalNets.TPLMD) / total),
        averageScore: generalNets.PUANORT,
        corpAverageNet: generalNets.TPLMN,
        examAverageNet: generalNets.SNVGNLN
      },
      treeData
    };
  }
};
