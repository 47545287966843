/*
Parse and format API for corporation level reports
TODO: all methods must be refactored. this file was copied from class.service.js
 */
import * as _ from "lodash";
import md5 from "crypto-js/md5";

import { SinavAPI } from "@/common/api";

function parseNetsByLesson(dataByLesson, generalData) {
  const lessons = [
    "Türkçe",
    "Matematik",
    "Fen Bilimleri",
    "Sosyal Bilgiler",
    "Yabancı Dil",
    "Din Kültürü"
  ];

  return lessons.map((lessonName, index) => {
    const i = index + 1;
    const correctKey = `BL${i}D`;
    const wrongKey = `BL${i}Y`;
    const emptyKey = `BL${i}B`;
    const netKey = `BL${i}N`;
    const generalNetKey = `BL${i}NG`;
    const percentKey = `BL${i}Z`;
    const rankKey = `BL${i}NS`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalData[generalNetKey],
      correctnessPercent: dataByLesson[percentKey],
      rank: dataByLesson[rankKey],
      meta: { ...dataByLesson, ...generalData }
    };
  });
}

function parseGeneralNetData(dataByLesson, generalData) {
  return {
    correct: dataByLesson.D_TOPLAM,
    wrong: dataByLesson.Y_TOPLAM,
    empty: dataByLesson.B_TOPLAM,
    net: dataByLesson.N_TOPLAM,
    averageNet: generalData.GNLSNVNET,
    rank: dataByLesson.GNLPUAN_GES,
    correctnessPercent: dataByLesson.TZ
  };
}

async function getPrevExamData({ examId, branchId }) {
  let { data: examData } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
    examId,
    branchId,
    action: 2
  });

  if (!examData.length) return [];
  [examData = {}] = examData;

  let { data: generalDataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
    examId: examData.ID_SINAVBILGI,
    branchId,
    action: 3
  });
  [generalDataByLesson = {}] = generalDataByLesson;

  return parseNetsByLesson(examData, generalDataByLesson);
}

function mergeGainsAndNets(gains, netsByLesson) {
  return netsByLesson.map(lesson => {
    const gainsBelongToLesson = gains.filter(gain => {
      const gainLesson = gain.DERSAD === "İngilizce" ? "Yabancı Dil" : gain.DERSAD;
      return gainLesson === lesson.lesson;
    });

    return {
      ...lesson,
      gains: gainsBelongToLesson
    };
  });
}

function parseNetsByLessonFromGains(data) {
  const pairs = {
    TRK: "Türkçe",
    MAT: "Matematik",
    FEN: "Fen Bilimleri",
    SOS: "Sosyal Bilgiler",
    ING: "Yabancı Dil",
    DIN: "Din Kültürü"
  };

  return Object.keys(pairs).map(key => {
    const correctKey = `${key}_D`;
    const wrongKey = `${key}_Y`;
    const emptyKey = `${key}_B`;
    const corpNetKey = `${key}_N`;
    const correctnessPercentKey = `${key}_Z`;
    const averageNetKey = `${key}_SNVGNLN`;

    return {
      lesson: pairs[key],
      correct: data[correctKey],
      wrong: data[wrongKey],
      empty: data[emptyKey],
      corpNet: data[corpNetKey],
      correctnessPercent: data[correctnessPercentKey],
      averageNet: data[averageNetKey]
    };
  });
}

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    const correctKey = "DOĞRU";
    const totalQuestionKey = "SORU SAYISI";
    const wrongKey = "YANLIŞ";
    const emptyKey = "BOŞ";
    const unitKey = "ÜNİTE";
    const topicKey = "KONU";
    const gainKey = "KAZANIM";

    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue[correctKey];
        const total = accumulator.total + currentValue[totalQuestionKey];
        const percent = Math.floor((100 * correct) / total);

        const id = `id_${currentValue[idKey]}`;
        const unitId = `unit_${currentValue[unitKey]}`;
        const topicId = `topic_${currentValue[topicKey]}`;
        const gainId = `gain_${currentValue[gainKey]}`;

        return {
          _id: md5(currentValue[idKey]).toString(),
          id: md5(id).toString(),
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue[wrongKey],
          empty: accumulator.empty + currentValue[emptyKey],
          percent,
          _unitId: md5(currentValue[unitKey]).toString(),
          unitId: md5(unitId).toString(),
          _topicId: md5(currentValue[unitKey]).toString(),
          topicId: md5(topicId).toString(),
          _gainId: md5(currentValue[gainKey]).toString(),
          gainId: md5(gainId).toString(),
          _meta: { ...currentValue }
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function generateTreeData(netsByLesson, gainsByLesson) {
  return netsByLesson.map(netByLesson => {
    const lessonName = netByLesson.lesson === "Yabancı Dil" ? "İngilizce" : netByLesson.lesson;
    const gainsBelongToLesson = gainsByLesson.filter(
      gain => gain.DERS === lessonName && gain.KAZANIM
    );

    let units = _.groupBy(gainsBelongToLesson, "ÜNİTE");
    let topics = _.groupBy(gainsBelongToLesson, "KONU");
    let gains = _.groupBy(gainsBelongToLesson, "KAZANIM");

    units = mergeGainsBy({
      data: units,
      idKey: "ÜNİTE",
      titleKey: "ÜNİTE"
    });
    topics = mergeGainsBy({
      data: topics,
      idKey: "KONU",
      titleKey: "KONU"
    });
    gains = mergeGainsBy({
      data: gains,
      idKey: "KAZANIM",
      titleKey: "KAZANIM"
    });

    topics = topics.map(topic => {
      return {
        ...topic,
        gains: gains.filter(gain => gain._topicId === topic._topicId)
      };
    });

    units = units.map(unit => {
      return {
        ...unit,
        topics: topics.filter(topic => topic._unitId === unit._id)
      };
    });

    return {
      ...netByLesson,
      percent: netByLesson.correctnessPercent, // quick fix for gain tree
      units,
      total: units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0)
    };
  });
}

function parsePeriodicTableDataByLesson(dataByLesson, averageData) {
  const types = {
    SOCIAL: "Sözel Bölüm",
    SCIENCE: "Sayısal Bölüm"
  };
  const lessonSections = {
    1: {
      type: types.SOCIAL,
      lessonName: "Türkçe",
      corpAverageKey: "TRK_Okl_Dsbs",
      generalAverageKey: "TRK_Okl_GNLDsbs",
      len: 21
    },
    2: {
      type: types.SCIENCE,
      lessonName: "Matematik",
      corpAverageKey: "MAT_Okl_Dsbs",
      generalAverageKey: "MAT_Okl_GNLDsbs",
      len: 21
    },
    3: {
      type: types.SCIENCE,
      lessonName: "Fen Bilimleri",
      corpAverageKey: "FEN_Okl_Dsbs",
      generalAverageKey: "FEN_Okl_GNLDsbs",
      len: 21
    },
    4: {
      type: types.SOCIAL,
      lessonName: "Sosyal Bilgiler",
      corpAverageKey: "SOS_Okl_Dsbs",
      generalAverageKey: "SOS_Okl_GNLDsbs",
      len: 11
    },
    5: {
      type: types.SOCIAL,
      lessonName: "Yabancı Dil",
      corpAverageKey: "ING_Okl_Dsbs",
      generalAverageKey: "ING_Okl_GNLDsbs",
      len: 11
    },
    6: {
      type: types.SOCIAL,
      lessonName: "Din Kültürü",
      corpAverageKey: "DIN_Okl_Dsbs",
      generalAverageKey: "DIN_Okl_GNLDsbs",
      len: 11
    }
  };
  const groupedDataByLesson = _.groupBy(dataByLesson, "BLNO");

  const formattedDataByLesson = Object.keys(groupedDataByLesson).map(lessonNo => {
    const lessonNoAsNumber = Number(lessonNo);
    if (!Number.isInteger(lessonNoAsNumber)) return {};

    const lessonMeta = lessonSections[lessonNoAsNumber];
    const lessonName = lessonMeta.lessonName;
    const corpAverage = averageData[lessonMeta.corpAverageKey];
    const generalAverage = averageData[lessonMeta.generalAverageKey];

    const emptyKey = `${lessonNoAsNumber}B`;
    let empty = dataByLesson.find(data => data.BLNO === emptyKey) || { NUM: 0 };
    empty = empty.NUM;

    const rawData = [...groupedDataByLesson[lessonNo]];
    const numbers = Array(lessonMeta.len)
      .fill(0)
      .map((_, index) => {
        const correctValue = rawData.find(data => data.BL1D === index) || { NUM: 0 };
        return correctValue.NUM;
      });

    return {
      lesson: lessonName,
      corpAverage,
      generalAverage,
      empty,
      numbers,
      _type: lessonMeta.type
    };
  });

  return [
    {
      type: types.SOCIAL,
      lessons: formattedDataByLesson.filter(data => data._type === types.SOCIAL)
    },
    {
      type: types.SCIENCE,
      lessons: formattedDataByLesson.filter(data => data._type === types.SCIENCE)
    }
  ];
}

function parsePeriodicTableDataByTotalCorrect(correctCountByTotal) {
  const ranges = [
    {
      range: [0, 20],
      desc: `Konu eksiği <strong>çok fazla</strong> olan ve <strong>çok az</strong> soru çözen öğrenci grubu.`
    },
    {
      range: [21, 45],
      desc: `Konu eksiği <strong>fazla</strong> olan ve <strong>az</strong> soru çözen öğrenci grubu.`
    },
    {
      range: [46, 70],
      desc: `Konu eksiği <strong>az</strong> olan ve <strong>yeterince</strong> soru çözmeyen öğrenci grubu.`
    },
    {
      range: [71, 90],
      desc: `Konu eksiği <strong>olmayan</strong> ve <strong>farklı soru tipi</strong> çözmesi gereken öğrenci grubu.`
    }
  ];

  return ranges.map((range, rangeIndex) => {
    let [start, end] = range.range;
    const prevRangeEnd = rangeIndex > 0 ? ranges[rangeIndex - 1].range[1] : 0;
    const rangeLen = end - start + 1;
    const numbers = Array(rangeLen)
      .fill(0)
      .map((_, i) => {
        const index = i + prevRangeEnd + 1;
        const obj = correctCountByTotal.find(data => data.TOPLAMD === index) || { NUM: 0 };
        return obj.NUM;
      });

    return {
      range: `${start} - ${end}`,
      desc: range.desc,
      numbers,
      total: numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    };
  });
}

function parseAllCorrect(data) {
  const lessons = [
    { key: "TRKTAM", lesson: "Türkçe" },
    { key: "MATTAM", lesson: "Matematik" },
    { key: "FENTAM", lesson: "Fen Bilimleri" },
    { key: "SOSTAM", lesson: "Sosyal Bilgiler" },
    { key: "INGTAM", lesson: "Yabancı Dil" },
    { key: "DINTAM", lesson: "Din Kültürü" }
  ];

  const byLesson = lessons.map(lesson => {
    return {
      lesson: lesson.lesson,
      count: data[lesson.key]
    };
  });

  return {
    all: data.TAM,
    byLesson
  };
}

// eslint-disable-next-line no-unused-vars
function sectionNoToLessonName(gains) {
  const groupedGains = _.groupBy(gains, "BOLUMNO");
  const lessonSections = {
    1: "Türkçe",
    2: "Matematik",
    3: "Fen Bilimleri",
    4: "Sosyal Bilgiler",
    5: "Yabancı Dil",
    6: "Din Kültürü"
  };

  return Object.keys(groupedGains).map(sectionNo => {
    const lesson = lessonSections[sectionNo];
    return {
      lesson,
      gains: groupedGains[sectionNo]
    };
  });
}

export default {
  async frontExamReport({ examId, branchId }) {
    /**
     * islem 1: ders bazlı veriler
     * islem 2: bir önceki sınav ile kıyaslama
     * islem 3: genel net bilgileri
     */
    let { data: dataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 1
    });
    [dataByLesson = {}] = dataByLesson;

    let { data: generalDataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 3
    });
    [generalDataByLesson = {}] = generalDataByLesson;

    let { data: attendanceAndCountData } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 4
    });
    [attendanceAndCountData = {}] = attendanceAndCountData;

    let { data: allCorrectData } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 5
    });
    [allCorrectData = {}] = allCorrectData;

    const allCorrect = parseAllCorrect(allCorrectData);
    const prevExam = await getPrevExamData({ examId, branchId });
    return {
      netsByLesson: parseNetsByLesson(dataByLesson, generalDataByLesson),
      generalNetData: parseGeneralNetData(dataByLesson, generalDataByLesson),
      averageScore: generalDataByLesson.GNLPUAN700GEO,
      corpScore: dataByLesson.GNLPUAN_SNV,
      corpScorePercent: dataByLesson.SNVYZ,
      attendance: {
        generalAttendance: generalDataByLesson.SINAV_KATILIM,
        classAttendance: dataByLesson.OGRSAY,
        corpAttendance: attendanceAndCountData.OKULSINIFKATILIM,
        corpCount: generalDataByLesson.OKULSAYISI,
        classCount: generalDataByLesson.SINIFSAYISI,
        corpRank: dataByLesson.GNLPUAN_GES
      },
      prevExam,
      allCorrect
    };
  },

  async backExamReport({ examId, branchId }) {
    let { data: gains } = await SinavAPI.KurumSinavAnaliziTumVerilerArka({
      examId,
      branchId,
      action: 1
    });

    let { data: dataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 1
    });
    [dataByLesson = {}] = dataByLesson;

    let { data: generalDataByLesson } = await SinavAPI.KurumSinavAnaliziTumVerilerOn({
      examId,
      branchId,
      action: 3
    });
    [generalDataByLesson = {}] = generalDataByLesson;

    dataByLesson = parseNetsByLesson(dataByLesson, {});
    //gains = sectionNoToLessonName(gains);
    const netsWithGains = mergeGainsAndNets(gains, dataByLesson);
    const attendance = generalDataByLesson.SINAV_KATILIM || 0;

    return {
      gains: netsWithGains,
      attendance
    };
  },

  async gainsTree({ examIdList, branchId }) {
    let { data: generalNets } = await SinavAPI.KurumKazanimAnaliziTumVeriler({
      examIdList,
      branchId,
      action: 3,
      section: 2
    });
    [generalNets = {}] = generalNets;

    let { data: gainsByLesson } = await SinavAPI.KurumKazanimAnaliziTumVeriler({
      examIdList,
      branchId,
      action: 1,
      section: 1
    });

    const netsByLesson = parseNetsByLessonFromGains(generalNets);
    const treeData = generateTreeData(netsByLesson, gainsByLesson);
    const total = generalNets.TPLMD + generalNets.TPLMY + generalNets.TPLMB;

    return {
      general: {
        correct: generalNets.TPLMD,
        wrong: generalNets.TPLMY,
        empty: generalNets.TPLMB,
        correctnessPercent: Math.floor((100 * generalNets.TPLMD) / total),
        averageScore: generalNets.PUANORT,
        corpAverageNet: generalNets.TPLMN,
        examAverageNet: generalNets.SNVGNLN
      },
      treeData
    };
  },

  async podium({ examId }) {
    const { data: podiumData } = await SinavAPI.KurumPodyumAnaliziTumVeriler({ examId });

    return podiumData
      .sort((a, b) => (a.PUAN < b.PUAN ? -1 : 1))
      .reverse()
      .map((data, i) => {
        return {
          name: data.ADSOYAD,
          school: data.OKULAD,
          score: data.PUAN,
          rank: i + 1
        };
      });
  },

  async periodicTable({ examId, branchId }) {
    const { data: correctCountByLesson } = await SinavAPI.KurumPeryodikDogruAnaliziTabloVeriler({
      examId,
      branchId,
      section: 1
    });

    let { data: averageData } = await SinavAPI.KurumPeryodikDogruAnaliziTabloVeriler({
      examId,
      branchId,
      section: 2
    });
    [averageData] = averageData;

    const { data: correctCountByTotal } = await SinavAPI.KurumPeryodikDogruAnaliziTabloVeriler({
      examId,
      branchId,
      section: 3
    });

    const dataByLesson = parsePeriodicTableDataByLesson(correctCountByLesson, averageData);
    const dataByTotal = parsePeriodicTableDataByTotalCorrect(correctCountByTotal);

    return {
      byLesson: dataByLesson,
      byTotal: dataByTotal,
      studentAttendance: averageData.OGRSAY || 0
    };
  }
};
