<template>
    <div class="w-100" :style="style"></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "linebar",
  props: {
    variant: {
      type: String,
      default: "first"
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    style() {
      let firstColor = this.theme.colors.BLUE;
      let secondColor = this.theme.colors.RED;
      if (this.$store.getters.form.corp == 1) {
        firstColor = this.theme.colors.KULTUR_FIRST;
        secondColor = this.theme.colors.KULTUR_SECOND;
      } else if (this.$store.getters.form.corp == 3) {
        firstColor = this.theme.colors.ERA_FIRST;
        secondColor = this.theme.colors.ERA_SECOND;
      }
      return {
        background: firstColor,
        backgroundImage: `linear-gradient(45deg, ${firstColor} 50%, ${secondColor} 50%)`,
        backgroundSize: "100% 100%",
        height: "12.34px"
      };
    }
  }
};
</script>
