<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex align-items-center" v-if="classNet !== undefined" :style="outerStyle">
      <img :src="classNetSvg" alt="class" :style="iconStyle" />
      <span :style="textStyle">{{ toFixed(classNet) }}</span>
    </div>
    <div class="d-flex align-items-center" v-if="corpNet !== undefined" :style="outerStyle">
      <img :src="schoolOrangeSvg" alt="corp" :style="iconStyle" />
      <span :style="textStyle">{{ toFixed(corpNet) }}</span>
    </div>
    <div class="d-flex align-items-center" v-if="studentNet !== undefined" :style="outerStyle">
      <img :src="studentNetSvg" alt="student" :style="iconStyle" />
      <span :style="textStyle">{{ toFixed(studentNet) }}</span>
    </div>
    <div class="d-flex align-items-center" :style="outerStyle">
      <img :src="averageNetSvg" alt="student" :style="iconStyle" />
      <span :style="textStyle">{{ toFixed(averageNet) }}</span>
    </div>
    <div class="d-flex align-items-center" v-if="rank !== undefined" :style="outerStyle">
      <img :src="barsSvg" alt="rank" :style="iconStyle" />
      <span :style="textStyle">{{ rank }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import schoolOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/school-orange.svg";
import studentSvg from "@/assets/media/icons/svg/Karnem/lgs/student.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/lgs/students.svg";
import barsSvg from "@/assets/media/icons/svg/Karnem/lgs/bars.svg";
import studentChairOrangeSvg from "@/assets/media/icons/svg/Karnem/lgs/student-chair-orange.svg";
import yksStudentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import yksStudentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";
import { EXAM_TYPES, ALLOWED_EXAM_TYPES } from "@/common/config";

export default {
  name: "net",
  props: {
    studentNet: {
      type: [Number, String],
      default: undefined
    },
    classNet: {
      type: [Number, String],
      default: undefined
    },
    corpNet: {
      type: [Number, String],
      default: undefined
    },
    averageNet: {
      type: [Number, String],
      default: undefined
    },
    rank: {
      type: [Number, String],
      default: undefined
    },
    variant: {
      type: String, // "medium", "large"
      default: "medium",
      validator(value) {
        return ["medium", "large"].indexOf(value) !== -1;
      }
    },
    type: {
      type: Number,
      default: EXAM_TYPES.SBS,
      validator(value) {
        return ALLOWED_EXAM_TYPES.indexOf(value) !== -1;
      }
    },
    iconStyles: {
      type: Object,
      required: false,
      default: undefined
    },
    textStyles: {
      type: Object,
      required: false,
      default: undefined
    },
    outerStyles: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      schoolOrangeSvg,
      barsSvg
    };
  },
  methods: {
    toFixed(n) {
      if (typeof n === "number") {
        return n.toFixed(2);
      }
      return n;
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    studentNetSvg() {
      switch (this.type) {
        case EXAM_TYPES.SBS:
          return studentSvg;
        case EXAM_TYPES.YKS:
        case EXAM_TYPES.TYT:
          return yksStudentSvg;
        default:
          return null;
      }
    },
    averageNetSvg() {
      switch (this.type) {
        case EXAM_TYPES.SBS:
          return studentsSvg;
        case EXAM_TYPES.YKS:
        case EXAM_TYPES.TYT:
          return yksStudentsSvg;
        default:
          return null;
      }
    },
    classNetSvg() {
      switch (this.type) {
        case EXAM_TYPES.SBS:
        case EXAM_TYPES.YKS:
        case EXAM_TYPES.TYT:
          return studentChairOrangeSvg;
        default:
          return null;
      }
    },
    propsLen() {
      const { studentNet, classNet, corpNet, averageNet, rank } = this.$props;
      const props = [studentNet, classNet, corpNet, averageNet, rank].filter(
        val => typeof val === "number"
      );
      return props.length;
    },
    iconStyle() {
      const height = this.variant === "large" ? "32px" : "19px";
      const marginRight = this.variant === "large" ? "7px" : "4px";
      return {
        height,
        marginRight,
        ...this.iconStyles
      };
    },
    textStyle() {
      const fontSize = this.variant === "large" ? "31px" : "14px";
      return {
        fontSize,
        fontFamily: "'GothamCondensedBold'",
        lineHeight: 1,
        marginTop: "5px",
        ...this.textStyles
      };
    },
    outerStyle() {
      const marginRight = this.variant === "large" ? "8px" : "3.5px";
      const marginLeft = this.variant === "large" ? "8px" : "3.5px";
      return {
        marginRight,
        marginLeft,
        ...this.outerStyles
      };
    }
  }
};
</script>
