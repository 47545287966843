<template>
  <div class="header">
    <div class="d-flex align-items-center">
      <Icon
        :src="(variant == 'student' && studentSvg) || (variant == 'class' ? studentsSvg : corpSvg)"
        :height="33"
      ></Icon>
      <div>
        <h1>{{ leftTitle }}</h1>
        <h4 v-if="leftSubtitle">{{ leftSubtitle }}</h4>
      </div>
    </div>
    <div class="ml-auto">
      <img v-if="!hideLogo" alt="logo" :src="logo" class="header-img" />
      <h4 v-if="rightSubtitle" class="ml-0">{{ rightSubtitle }}</h4>
    </div>
  </div>
</template>

<script>
import studentSvg from "@/assets/media/icons/svg/Karnem/yks/student.svg";
import studentsSvg from "@/assets/media/icons/svg/Karnem/yks/students.svg";
import corpSvg from "@/assets/media/icons/svg/Karnem/yks/school.svg";
import Icon from "@/components/icons/icon.component";

import { mapGetters } from "vuex";
export default {
  name: "report-header",
  props: {
    leftTitle: {
      type: String,
      required: true
    },
    leftSubtitle: {
      type: String,
      required: false,
      default: null
    },
    rightSubtitle: {
      type: String,
      required: false,
      default: null
    },
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["theme", "form"]),
    logo() {
      const { logo } = this.theme;
      return logo;
    },
    variant() {
      return this.form.variant;
    }
  },
  components: {
    Icon
  },
  data() {
    return { studentSvg, studentsSvg, corpSvg };
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  background-color: #edeeef;
  color: #6d6f72;
  padding: 3px 15px 3px 3px;
  min-height: 30px;
  font-family: "GothamBlack";
  margin-bottom: 25px;
  @media (min-width: 768px) {
    min-height: 60px;
  }
  @media (min-width: 1200px) {
    min-height: 71px;
    padding: 10px 65px 10px 30px;
  }
  &-img {
    max-width: 100%;
    max-height: 11px;
    @media (min-width: 768px) {
      max-height: 24px;
    }
    @media (min-width: 1200px) {
      max-height: 28px;
    }
  }
  img:not(.header-img) {
    height: 18px;
    margin-right: 3.5px;
    @media (min-width: 768px) {
      height: 33px;
    }
    @media (min-width: 1200px) {
      margin-right: 11px;
    }
  }
  h1 {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 5px;
    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 25px;
    }
  }
  h4 {
    font-size: 12px;
    font-family: "GothamNarrowBold";
    line-height: 1;
    margin-bottom: 0;
    @media (min-width: 768px) {
      font-size: 15px;
    }
  }
}
</style>
