<template>
  <div class="tree">
    <ul class="level_1">
      <li>
        <GainTreeLabel :style="{ marginBottom: '10px' }" is-title></GainTreeLabel>
        <template v-for="(treeData, listedTreeDataIndex) of listedTreeData">
          <b-row :key="`row_${listedTreeDataIndex}`">
            <b-col class="d-flex">
              <div class="title flex-grow-1">
                <a
                  data-toggle="collapse"
                  :href="`#${rootId(listedTreeDataIndex, treeData.lesson)}`"
                  aria-expanded="true"
                  :aria-controls="`#${rootId(listedTreeDataIndex, treeData.lesson)}`"
                  :style="titleStyles.lesson"
                >
                  <fa icon="plus-square"></fa>{{ treeData.lesson.trim() }}
                </a>
              </div>
              <GainTreeLabel
                :values="{
                  totalQuestion: treeData.total,
                  correct: treeData.correct,
                  wrong: treeData.wrong,
                  empty: treeData.empty,
                  percent: percent(treeData)
                }"
              ></GainTreeLabel>
            </b-col>
          </b-row>
          <div
            :id="`${rootId(listedTreeDataIndex, treeData.lesson)}`"
            class="collapse"
            :class="listTypeRadio > 1 && 'show'"
            :key="`root_${listedTreeDataIndex}`"
          >
            <ul class="level_2" v-for="(unit, unit_index) in treeData.units" :key="unit.id">
              <li :class="isLast(unit_index, treeData.units)">
                <b-row>
                  <b-col class="d-flex">
                    <div class="title d-flex flex-row flex-grow-1">
                      <a
                        data-toggle="collapse"
                        :href="`#unit${unit.id}`"
                        aria-expanded="false"
                        :aria-controls="`unit${unit.id}`"
                        :style="titleStyles.unit"
                      >
                        <fa icon="plus-square"></fa>{{ unit.title.trim() }}
                      </a>
                      <div
                        class="flex-grow-1"
                        :style="titleBorderStyle(titleStyles.unit.color)"
                      ></div>
                    </div>
                    <GainTreeLabel
                      :values="{
                        totalQuestion: unit.total,
                        correct: unit.correct,
                        wrong: unit.wrong,
                        empty: unit.empty,
                        percent: percent(unit)
                      }"
                    ></GainTreeLabel>
                  </b-col>
                </b-row>
                <div :id="`unit${unit.id}`" class="collapse" :class="listTypeRadio > 2 && 'show'">
                  <ul class="level_3">
                    <li
                      v-for="(topic, topicId) in unit.topics"
                      :key="topicId"
                      :class="isLast(topicId, unit.topics)"
                    >
                      <b-row>
                        <b-col class="d-flex">
                          <div class="title d-flex flex-row flex-grow-1">
                            <a
                              data-toggle="collapse"
                              :href="`#topic${topicId}${unit.id}`"
                              aria-expanded="false"
                              :aria-controls="`topic${topicId}${unit.id}`"
                              :style="titleStyles.topic"
                            >
                              <fa icon="plus-square"></fa>{{ topic.title.trim() }}
                            </a>
                            <div
                              class="flex-grow-1"
                              :style="titleBorderStyle(titleStyles.topic.color)"
                            ></div>
                          </div>
                          <GainTreeLabel
                            :values="{
                              totalQuestion: topic.total,
                              correct: topic.correct,
                              wrong: topic.wrong,
                              empty: topic.empty,
                              percent: percent(topic)
                            }"
                          ></GainTreeLabel>
                        </b-col>
                      </b-row>
                      <div
                        :id="`topic${topicId}${unit.id}`"
                        class="collapse"
                        :class="listTypeRadio > 3 && 'show'"
                      >
                        <ul class="level_4">
                          <li v-for="(gain, gainId) in topic.gains" :key="gainId">
                            <b-row>
                              <b-col class="d-flex">
                                <div
                                  class="title h-100 d-flex align-items-center flex-grow-1"
                                  :style="titleStyles.gain"
                                >
                                  <fa icon="angle-double-right"></fa>{{ gain.title.trim() }}
                                </div>
                                <GainTreeLabel
                                  :values="{
                                    totalQuestion: gain.total,
                                    correct: gain.correct,
                                    wrong: gain.wrong,
                                    empty: gain.empty,
                                    percent: percent(gain),
                                    topicId: gain.topicId,
                                    id: gainId,
                                    gainId: gain.id
                                  }"
                                  :lesson="treeData.lesson"
                                ></GainTreeLabel>
                              </b-col>
                            </b-row>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import md5 from "crypto-js/md5";

import GainTreeLabel from "@/components/reports/gains/gains-tree-label.vue";

export default {
  name: "tree-table",
  props: {
    treeData: {
      type: [Object, Array], // see: expectedDataFormat in computed
      required: true
    },
    listTypeRadio: {
      type: Number
    }
  },
  components: {
    GainTreeLabel
  },
  methods: {
    rootId(...params) {
      return `root_${md5(params.toString()).toString()}`;
    },
    percent(obj) {
      if (obj.percent === undefined) {
        return obj.correctnessPercent || 0;
      }

      return obj.percent;
    },
    isLast(index, items) {
      if (items.length === 1) return "single";
      return index === items.length - 1 ? "last" : null;
    },
    titleBorderStyle(color) {
      return {
        borderBottom: `1px solid ${color}`,
        // marginBottom: "5px",
        opacity: 0.25
      };
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    expectedDataFormat() {
      // leaved here for documentation
      return {
        lesson: "Matematik",
        total: 30,
        correct: 22,
        wrong: 5,
        empty: 3,
        percent: 88, // or correctnessPercent
        units: [
          {
            title: "Çarpanlar ve Katlar, Üslü Sayılar",
            total: 15,
            correct: 12,
            wrong: 2,
            empty: 1,
            percent: 75,
            topics: [
              {
                title: "Çarpanlar ve Katlar",
                total: 10,
                correct: 8,
                wrong: 2,
                empty: 0,
                percent: 80,
                gains: [
                  {
                    title: "Pozitif tam sayıların çarpanları",
                    total: 3,
                    correct: 2,
                    wrong: 1,
                    empty: 0,
                    percent: 66
                  },
                  {
                    title: "OBEB ve OKEK",
                    total: 3,
                    correct: 3,
                    wrong: 0,
                    empty: 0,
                    percent: 100
                  }
                ]
              },
              {
                title: "Üslü İfadeler",
                total: 10,
                correct: 8,
                wrong: 2,
                empty: 0,
                percent: 80,
                gains: [
                  {
                    title: "Tam Sayıların Tam Kuvvetleri",
                    total: 3,
                    correct: 2,
                    wrong: 1,
                    empty: 0,
                    percent: 66
                  },
                  {
                    title: "Üslü ifadeler ve işlemler",
                    total: 3,
                    correct: 3,
                    wrong: 0,
                    empty: 0,
                    percent: 100
                  }
                ]
              }
            ]
          },
          {
            title: "Kareköklü İfadeler, Veri Analizi",
            total: 22,
            correct: 10,
            wrong: 2,
            empty: 5,
            percent: 45,
            topics: [
              {
                title: "Kareköklü İfadeler",
                total: 10,
                correct: 8,
                wrong: 2,
                empty: 0,
                percent: 80,
                gains: [
                  {
                    title: "Tam kare doğal sayılar",
                    total: 3,
                    correct: 2,
                    wrong: 1,
                    empty: 0,
                    percent: 66
                  },
                  {
                    title: "Ondalık İfadelerin Karekökü",
                    total: 3,
                    correct: 3,
                    wrong: 0,
                    empty: 0,
                    percent: 100
                  }
                ]
              },
              {
                title: "Veri Analizi",
                total: 10,
                correct: 8,
                wrong: 2,
                empty: 0,
                percent: 80,
                gains: [
                  {
                    title: "En fazla üç veri grubuna ait çizgi ve sütun grafikleri",
                    total: 3,
                    correct: 2,
                    wrong: 1,
                    empty: 0,
                    percent: 66
                  },
                  {
                    title: "Sütun, daire veya çizgi grafiği arasında uygun olan dönüşümleri yapma",
                    total: 3,
                    correct: 3,
                    wrong: 0,
                    empty: 0,
                    percent: 100
                  }
                ]
              }
            ]
          }
        ]
      };
    },
    listedTreeData() {
      if (Array.isArray(this.treeData)) {
        return this.treeData;
      }
      return [this.treeData];
    },
    titleStyles() {
      return {
        lesson: {
          color: this.theme.colors.GRAY
        },
        unit: {
          color: this.theme.colors.GREEN
        },
        topic: {
          color: this.theme.colors.LIGHT_GREEN,
          fontFamily: "GothamCondensedMedium"
        },
        gain: {
          color: this.theme.colors.GRAY,
          textTransform: "capitalize",
          fontSize: "10px",
          fontFamily: "GothamCondensedBook",
          borderRadius: 0,
          borderBottom: `1px solid #E8E8E8`
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.tree {
  margin-left: -31px;
  margin-right: -24px;
  @media (min-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }
  li {
    list-style-type: none;
    position: relative;
    &::before,
    &::after {
      content: "";
      left: -7px;
      position: absolute;
      right: auto;
      @media (min-width: 768px) {
        left: -12px;
      }
    }

    &::before {
      border-left: 1px solid #e8e8e8;
      height: calc(100% + 29px);
      top: -12px;
      width: 1px;
    }
    &::after {
      border-top: 1px solid #e8e8e8;
      height: 1px;
      top: 7px;
      width: 7px;
    }
  }
}

.tree .level_2 li.last::before {
  border: none;
}
.tree .level_2 li.single::before {
  height: 20px;
}
.tree .level_3 li.last::before {
  border: none;
}
.level_4 li::after {
  border: none;
  top: inherit;
  bottom: 0;
  height: 20px;
}
.tree li .title {
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.tree > ul > li::before,
.tree > ul > li::after {
  border: 0;
}

.title,
.title a {
  font-family: "GothamCondensedBold";
  text-decoration: none;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 15.18px;
  }
  svg {
    position: relative;
    font-size: 12px;
    transform: translateY(-1px);
    margin-right: 4px;
  }
}
ul[class*="level"] {
  margin-bottom: 10px;
  margin-top: 10px;
  &:not(.level_4) {
    padding-left: 7px;
    @media (min-width: 768px) {
      padding-left: 17px;
    }
  }
  &.level_4 {
    padding-left: 7px;
    li:before {
      left: 0;
      height: calc(100% + 12px);
    }
    .title {
      padding-left: 5px;
      @media (min-width: 768px) {
        padding-left: 10px;
      }
      svg {
        font-size: 6px;
      }
    }
  }
}
[aria-expanded="false"] > .expanded,
[aria-expanded="true"] > .collapsed {
  display: none;
}
</style>
