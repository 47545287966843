import { ptToPx } from "@/common/utils";

const mixin = {
  methods: {
    ptToPx(n) {
      return ptToPx(n, true);
    }
  }
};

export default mixin;
