const mixin = {
  methods: {
    renameLesson(s) {
      switch (s) {
        case "İngilizce":
          return "Yabancı Dil";
        default:
          return s;
      }
    }
  }
};

export default mixin;
