<template>
  <div class="d-flex flex-column align-items-center" :style="styleOuter">
    <h1 v-if="title" :style="getTitleStyle">{{ renameLesson(title) }}</h1>
    <DoughnutChart
      :chart-data="chartData"
      :styles="styles"
      :slice-font-size="sliceFontSize"
      :inner-font-size="innerFontSize"
      :inner-font-style="innerFontStyle"
      :cutout-percentage="cutoutPercentage"
    />
  </div>
</template>
<script>
import DoughnutChart from "@/components/charts/doughnut.mixin.vue";
import RenameLessonMixin from "@/components/mixins/rename-lesson.mixin";

export default {
  mixins: [RenameLessonMixin],
  props: {
    sliceFontSize: {
      type: Number,
      default: undefined
    },
    innerFontSize: {
      type: Number,
      default: undefined
    },
    innerFontStyle: {
      type: String,
      default: undefined
    },
    cutoutPercentage: {
      type: Number,
      default: undefined
    },
    styles: {
      type: Object,
      default: undefined
    },
    stylesOuter: {
      type: Object,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    titleStyle: {
      type: Object,
      default: null
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  components: {
    DoughnutChart
  },
  computed: {
    getTitleStyle() {
      return {
        ...(this.titleStyle || {})
      };
    },
    styleOuter(){
      return this.stylesOuter 
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "GothamNarrowBlack";
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 8px;
  font-size: 15px;
}
</style>
