<script>
import { mapGetters } from "vuex";
import { Doughnut } from "vue-chartjs";

import { ptToPx } from "@/common/utils";

export default {
  extends: Doughnut,
  props: {
    sliceFontSize: {
      type: Number,
      default: ptToPx(9, true)
    },
    innerFontSize: {
      type: Number,
      default: ptToPx(23, true)
    },
    innerFontStyle: {
      type: String,
      default: "GothamCondensedBold"
    },
    cutoutPercentage: {
      type: Number,
      default: 65
    },
    chartData: {
      type: Object,
      default: null
    }
  },
  mounted() {
    const {
      innerFontSize,
      innerFontStyle: fontStyle,
      theme: { colors }
    } = this;
    this.addPlugin({
      beforeDraw(chart) {
        if (!chart.config.options.elements.center) {
          return;
        }
        const { ctx } = chart.chart;
        // Get options from the center object in options
        const centerConfig = chart.config.options.elements.center;
        const txt = centerConfig.text;
        const color = colors.GRAY;
        const sidePadding = centerConfig.sidePadding || 20;
        const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
        ctx.font = `${innerFontSize}px ${fontStyle}`;

        // Get the width of the string and also
        // the width of the element minus 10 to give it 5px side padding
        const stringWidth = ctx.measureText(txt).width;
        const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        const widthRatio = elementWidth / stringWidth;
        const newFontSize = Math.floor(30 * widthRatio);
        const elementHeight = chart.innerRadius * 2;

        // Pick a new font size so it will not be larger than the height of label.
        // eslint-disable-next-line no-unused-vars
        const fontSizeToUse = Math.min(newFontSize, elementHeight);

        // Set font settings to draw it correctly.
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = `${innerFontSize}px ${fontStyle}`;
        ctx.fillStyle = color;

        // Draw text in center
        ctx.fillText(txt, centerX, centerY);
      }
    });
    setTimeout(this.chart, 500);
  },
  watch: {
    chartData() {
      this.chart();
    }
  },
  methods: {
    chart() {
      const {
        sliceFontSize,
        theme: { colors }
      } = this;

      this.renderChart(this.formattedChartData, {
        elements: {
          center: {
            text: `%${this.chartData.percent}`,
            color: colors.GRAY, // Default is #000000
            fontStyle: this.innerFontStyle, // Default is Arial
            sidePadding: 5 // Defualt is 20 (as a percentage)
          }
        },
        plugins: {
          datalabels: {
            color: "#fff",
            font: {
              size: sliceFontSize,
              family: "GothamCondensedBold"
            }
          }
        },
        cutoutPercentage: this.cutoutPercentage,
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        ...this.options
      });
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    formattedChartData() {
      var chartT = this.chartData.chartData.datasets[0];
      if (this.$store.getters.form.corp == 1) {
        chartT.backgroundColor[0] = this.theme.colors.KULTUR_FIRST;
        chartT.backgroundColor[1] = this.theme.colors.KULTUR_SECOND;
      } else if (this.$store.getters.form.corp == 3) {
        chartT.backgroundColor[0] = this.theme.colors.ERA_FIRST;
        chartT.backgroundColor[1] = this.theme.colors.ERA_SECOND;
      }
      return this.chartData.chartData;
    }
  }
};
</script>
