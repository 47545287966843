import * as _ from "lodash";
import md5 from "crypto-js/md5";

import { SinavAPI } from "@/common/api";

function parseMainNets(dataByLesson, generalData) {
  const lessons = [
    {
      lesson: "Türkçe",
      key: "TRK_",
      averageKey: "TRK_",
      rankKey: ""
    },
    {
      lesson: "Sosyal Bilimler",
      key: "SOS_",
      averageKey: "SOS_",
      rankKey: ""
    },
    {
      lesson: "Matematik",
      key: "MAT_",
      averageKey: "MAT",
      rankKey: ""
    },
    {
      lesson: "Fen Bilimleri",
      key: "FEN_",
      averageKey: "FEN",
      rankKey: ""
    }
  ];

  return lessons.map(lesson => {
    const { lesson: lessonName, key, averageKey } = lesson;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;
    const percentKey = `${key}Z`;
    const generalNetKey = `${averageKey}GN`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalData[generalNetKey],
      correctnessPercent: dataByLesson[percentKey],
      rank: 0, // TODO
      meta: { ...dataByLesson, ...generalData }
    };
  });
}

function parseNetsByLesson({ dataByLesson, generalNets }) {
  const lessons = [
    {
      lesson: "Türkçe",
      key: "BL1",
      rankKey: ""
    },
    {
      lesson: "Tarih",
      key: "BL2",
      rankKey: ""
    },
    {
      lesson: "Coğrafya",
      key: "BL3",
      rankKey: ""
    },
    {
      lesson: "Felsefe",
      key: "BL4",
      rankKey: ""
    },
    {
      lesson: "Din Kültürü",
      key: "BL5",
      rankKey: ""
    },
    {
      lesson: "Matematik",
      key: "BL6",
      rankKey: ""
    },
    {
      lesson: "Geometri",
      key: "BL7",
      rankKey: ""
    },
    {
      lesson: "Fizik",
      key: "BL8",
      rankKey: ""
    },
    {
      lesson: "Kimya",
      key: "BL9",
      rankKey: ""
    },
    {
      lesson: "Biyoloji",
      key: "BL10",
      rankKey: ""
    }
  ];

  return lessons.map(lesson => {
    let { lesson: lessonName, key } = lesson;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;
    const averageNetKey = `${key}GN`;
    const totalKey = `${key}SS`;

    return {
      lesson: lessonName,
      correct: dataByLesson[correctKey],
      wrong: dataByLesson[wrongKey],
      empty: dataByLesson[emptyKey],
      net: dataByLesson[netKey],
      averageNet: generalNets[averageNetKey],
      total: dataByLesson[totalKey],
      rank: 0 // TODO
    };
  });
}

function parseAttendances({ generalNets, attendanceData }) {
  return {
    general: generalNets.KATILIM,
    corp: generalNets.OKULSAYISI,
    class: attendanceData.OKULSINIFKATILIM
  };
}

function parseRanks(data) {
  return {
    corpAttendance: data.TYTPUAN_OKS,
    corpRank: data.TYTPUAN_GES,
    classAttendance: data.OGRSAY
  };
}

async function getPrevExamData({ examId, classId }) {
  let { data: examData } = await SinavAPI.SinifSinavAnaliziTumVeriler({
    examId,
    classId,
    action: 2
  });

  if (!examData) return [];
  [examData = {}] = examData;

  let { data: generalDataByLesson } = await SinavAPI.SinifSinavAnaliziTumVeriler({
    examId: examData.ID_SINAVBILGI,
    classId,
    action: 3
  });
  [generalDataByLesson = {}] = generalDataByLesson;

  return parseMainNets(examData, generalDataByLesson);
}

function parseQuestionAnalyze(questionAnalyzeData, netsByLesson) {
  const mainLessons = [
    {
      lesson: "Türkçe",
      key: "TRK_",
      subLessons: [{ lesson: "Türkçe", sectionNo: 1 }]
    },
    {
      lesson: "Sosyal Bilimler",
      key: "SOS_",
      subLessons: [
        { lesson: "Tarih", sectionNo: 2 },
        { lesson: "Coğrafya", sectionNo: 3 },
        { lesson: "Felsefe", sectionNo: 4 },
        { lesson: "Din Kül.", sectionNo: 5 }
      ]
    },
    {
      lesson: "Matematik",
      key: "MAT_",
      subLessons: [
        { lesson: "Matematik", sectionNo: 6 },
        { lesson: "Geometri", sectionNo: 7 }
      ]
    },
    {
      lesson: "Fen Bilimleri",
      key: "FEN_",
      subLessons: [
        { lesson: "Fizik", sectionNo: 8 },
        { lesson: "Kimya", sectionNo: 9 },
        { lesson: "Biyoloji", sectionNo: 10 }
      ]
    }
  ];

  return mainLessons.map(mainLesson => {
    const { lesson: mainLessonName, key, subLessons } = mainLesson;
    const correctKey = `${key}D`;
    const wrongKey = `${key}Y`;
    const emptyKey = `${key}B`;
    const netKey = `${key}N`;

    let gains = questionAnalyzeData
      .filter(qData => {
        const sectionNoList = subLessons.map(subLesson => subLesson.sectionNo);
        return sectionNoList.indexOf(qData.BOLUMNO) !== -1;
      })
      .map((qData, index) => {
        return {
          index: index + 1,
          a: qData.ASIK,
          b: qData.BSIK,
          c: qData.CSIK,
          d: qData.DSIK,
          e: qData.ESIK,
          empty: qData.BOSSIK,
          correctRatio: qData.YO,
          gain: qData.KAZANIM
        };
      });
    gains = _.chunk(gains, 20);

    return {
      lesson: mainLessonName,
      correct: netsByLesson[correctKey],
      wrong: netsByLesson[wrongKey],
      empty: netsByLesson[emptyKey],
      net: netsByLesson[netKey],
      gains
    };
  });
}

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    const correctKey = "DOĞRU";
    const totalQuestionKey = "SORU SAYISI";
    const wrongKey = "YANLIŞ";
    const emptyKey = "BOŞ";
    const unitKey = "ÜNİTE";
    const topicKey = "KONU";
    const gainKey = "KAZANIM";

    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue[correctKey];
        const total = accumulator.total + currentValue[totalQuestionKey];
        const percent = Math.floor((100 * correct) / total);

        const id = `id_${currentValue[idKey]}`;
        const unitId = `unit_${currentValue[unitKey]}`;
        const topicId = `topic_${currentValue[topicKey]}`;
        const gainId = `gain_${currentValue[gainKey]}`;

        return {
          _id: md5(currentValue[idKey]).toString(),
          id: md5(id).toString(),
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue[wrongKey],
          empty: accumulator.empty + currentValue[emptyKey],
          percent,
          _unitId: md5(currentValue[unitKey]).toString(),
          unitId: md5(unitId).toString(),
          _topicId: md5(currentValue[unitKey]).toString(),
          topicId: md5(topicId).toString(),
          _gainId: md5(currentValue[gainKey]).toString(),
          gainId: md5(gainId).toString()
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function generateTreeData(allGains) {
  const gainsMappedByLesson = _.groupBy(allGains, "DERS");

  return Object.keys(gainsMappedByLesson).map(lessonName => {
    const gainsBelongToLesson = allGains.filter(gain => gain.DERS === lessonName && !!gain.KAZANIM);

    let units = _.groupBy(gainsBelongToLesson, "ÜNİTE");
    let topics = _.groupBy(gainsBelongToLesson, "KONU");
    let _gains = _.groupBy(gainsBelongToLesson, "KAZANIM");

    units = mergeGainsBy({
      data: units,
      idKey: "ÜNİTE",
      titleKey: "ÜNİTE"
    });
    topics = mergeGainsBy({
      data: topics,
      idKey: "KONU",
      titleKey: "KONU"
    });
    _gains = mergeGainsBy({
      data: _gains,
      idKey: "KAZANIM",
      titleKey: "KAZANIM"
    });

    topics = topics.map(topic => {
      return {
        ...topic,
        gains: _gains.filter(gain => gain._topicId === topic._topicId)
      };
    });
    units = units.map(unit => {
      return {
        ...unit,
        topics: topics.filter(topic => topic._unitId === unit._id)
      };
    });

    const total = units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
    const correct = units.reduce(
      (accumulator, currentValue) => accumulator + currentValue.correct,
      0
    );
    const correctnessPercent = Math.floor((100 * correct) / total);

    return {
      lesson: lessonName,
      total,
      correct,
      wrong: units.reduce((accumulator, currentValue) => accumulator + currentValue.wrong, 0),
      empty: units.reduce((accumulator, currentValue) => accumulator + currentValue.empty, 0),
      correctnessPercent,
      units
    };
  });
}

function groupTreeDataByMainLesson(treeDataByLesson) {
  const mainLessons = [
    {
      lesson: "Türkçe",
      subLessons: ["Türkçe"]
    },
    {
      lesson: "Sosyal Bilimler",
      subLessons: ["Tarih", "Coğrafya", "Felsefe", "Din Kültürü"]
    },
    {
      lesson: "Matematik",
      subLessons: ["Matematik", "Geometri"]
    },
    {
      lesson: "Fen Bilimleri",
      subLessons: ["Fizik", "Kimya", "Biyoloji"]
    }
  ];

  return mainLessons.map(mainLesson => {
    return {
      lesson: mainLesson.lesson,
      subLessons: treeDataByLesson.filter(
        treeData => mainLesson.subLessons.indexOf(treeData.lesson) !== -1
      )
    };
  });
}

function mergeTreeDataAndAverageNets(treeDataByMainLesson, averageDataByMainLessons) {
  return treeDataByMainLesson.map(treeData => {
    const { lesson } = treeData;
    let netKey = "";
    let averageNetKey = "";

    switch (lesson) {
      case "Türkçe":
        netKey = "SNFSOS_1N";
        averageNetKey = "SNVGNLSOS_1GN";
        break;
      case "Sosyal Bilimler":
        netKey = "SNFSOS_2N";
        averageNetKey = "SNVGNLSOS_2GN";
        break;
      case "Matematik":
        netKey = "SNFMAT_N";
        averageNetKey = "SNVGNLMATGN";
        break;
      case "Fen Bilimleri":
        netKey = "SNFFEN_N";
        averageNetKey = "SNVGNLFENGN";
        break;
      default:
    }

    return {
      ...treeData,
      net: averageDataByMainLessons[netKey] || 0,
      averageNet: averageDataByMainLessons[averageNetKey] || 0
    };
  });
}

function parseAverageScoresFromComparison(classDataByExam, averageDataByExam) {
  return classDataByExam.map(classData => {
    const averageData = averageDataByExam.find(
      averageData => averageData.ID_SINAVBILGI === classData.ID_SINAVBILGI
    );
    return {
      exam: classData.SINAVAD,
      score: classData.TYTPUAN_SNV.toFixed(2),
      averageScore: averageData.TYTGEO.toFixed(2)
    };
  });
}

function parseMainLessonNetsFromComparison(classDataByExam, averageDataByExam) {
  const mainLessons = [
    {
      lesson: "Türkçe",
      netKey: "TRK_N",
      averageNetKey: "TRK_GN"
    },
    {
      lesson: "Sosyal Bilimler",
      netKey: "SOS_N",
      averageNetKey: "TAR_GN"
    },
    {
      lesson: "Matematik",
      netKey: "MAT_N",
      averageNetKey: "MAT_GN"
    },
    {
      lesson: "Fen Bilimleri",
      netKey: "FEN_N",
      averageNetKey: "FEN_GN"
    }
  ];

  return mainLessons.map(mainLesson => {
    const exams = classDataByExam.map(classData => {
      const averageData = averageDataByExam.find(
        data => data.ID_SINAVBILGI === classData.ID_SINAVBILGI
      );

      return {
        exam: classData.SINAVAD,
        net: classData[mainLesson.netKey],
        averageNet: averageData[mainLesson.averageNetKey]
      };
    });

    return {
      lesson: mainLesson.lesson,
      exams
    };
  });
}

function parseMainLessonAverageNetsFromComparison(classAverageData, generalAverageData) {
  const mainLessons = [
    {
      lesson: "Türkçe",
      netKey: "TRK_N",
      averageNetKey: "TRK_GN"
    },
    {
      lesson: "Sosyal Bilimler",
      netKey: "SOS_N",
      averageNetKey: "TAR_GN"
    },
    {
      lesson: "Matematik",
      netKey: "MAT_N",
      averageNetKey: "MAT_GN"
    },
    {
      lesson: "Fen Bilimleri",
      netKey: "FEN_N",
      averageNetKey: "FEN_GN"
    }
  ];

  return mainLessons.map(lesson => {
    return {
      lesson: lesson.lesson,
      net: classAverageData[lesson.netKey],
      averageNet: generalAverageData[lesson.averageNetKey]
    };
  });
}

function mergeMainLessonNetsFromComparison(mainLessons, averageNets) {
  return mainLessons.map(lesson => {
    const averageData = averageNets.find(averageData => averageData.lesson === lesson.lesson);
    return {
      ...lesson,
      ...averageData
    };
  });
}

function parseGeneralNets(dataByLesson, generalNets) {
  return {
    correct: dataByLesson.TD,
    wrong: dataByLesson.TY,
    empty: dataByLesson.TB,
    net: dataByLesson.TN,
    averageNet: generalNets.GN,
    correctnessPercent: dataByLesson.TZ,
    rank: 0 // TODO
  };
}

function parseAllCorrect(allCorrectData) {
  const lessonPairs = [
    {
      lesson: "Türkçe",
      key: "TRKTAM"
    },
    {
      lesson: "Tarih",
      key: "TARTAM"
    },
    {
      lesson: "Coğrafya",
      key: "COGTAM"
    },
    {
      lesson: "Felsefe",
      key: "FELTAM"
    },
    {
      lesson: "Din K.",
      key: "DINTAM"
    },
    {
      lesson: "Matematik",
      key: "MATTAM"
    },
    {
      lesson: "Geometri",
      key: "GEOTAM"
    },
    {
      lesson: "Fizik",
      key: "FENTAM"
    },
    {
      lesson: "Kimya",
      key: "KIMTAM"
    },
    {
      lesson: "Biyoloji",
      key: "BIYTAM"
    }
  ];

  const lessons = lessonPairs.map(lesson => {
    const { lesson: lessonName, key } = lesson;

    return {
      lesson: lessonName,
      count: allCorrectData[key]
    };
  });

  return {
    lessons,
    all: allCorrectData.TAM
  };
}

function parseNetsFromGainsTree() {
  return {
    correct: 1,
    wrong: 1,
    empty: 1,
    correctnessPercent: 1,
    net: 1,
    averageNet: 1
  };
}

function parsePercentAndScoreFromComparison() {
  return {
    percent: 1,
    score: 100
  };
}

export default {
  async frontReport({ examId, classId }) {
    let { data: dataByLesson } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 1
    });
    [dataByLesson = {}] = dataByLesson;

    let { data: generalNets } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 3
    });
    [generalNets = {}] = generalNets;

    let { data: attendanceData } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 4
    });
    [attendanceData = {}] = attendanceData;

    let { data: allCorrectData } = await SinavAPI.SinifSinavAnaliziTumVeriler({
      examId,
      classId,
      action: 5
    });
    [allCorrectData = {}] = allCorrectData;

    const mainNetsByLesson = parseMainNets(dataByLesson, generalNets);
    const generalNet = parseGeneralNets(dataByLesson, generalNets);
    const netsByLesson = parseNetsByLesson({ dataByLesson, generalNets });
    const allCorrect = parseAllCorrect(allCorrectData);
    const attendances = parseAttendances({ generalNets, attendanceData });
    const ranks = parseRanks(dataByLesson);
    const percent = 1; // TODO;
    const score = dataByLesson.TYTPUAN_SNV;
    const prevExam = await getPrevExamData({ examId, classId });

    return {
      mainNetsByLesson,
      generalNet,
      netsByLesson,
      allCorrect,
      attendances,
      ranks,
      percent,
      score,
      prevExam
    };
  },

  async questionAnalyze({ examId, examTypeId, classId }) {
    const { data: questionAnalyzeData } = await SinavAPI.SinifSinavAnaliziTumVerilerArka({
      examId,
      examTypeId,
      classId,
      action: 3 // 1 sbs, 2 tyt, 3 ayt
    });
    let { data: netsByLesson } = await SinavAPI.SinifSinavAnaliziTumVerilerArka({
      examId,
      examTypeId,
      classId,
      action: 4
    });
    [netsByLesson = {}] = netsByLesson;

    const attendance = netsByLesson.OGRSAY || 0;
    const questionAnalyzeByLesson = parseQuestionAnalyze(questionAnalyzeData, netsByLesson);

    return {
      questionAnalyzeByLesson,
      attendance
    };
  },

  async gainsTree({ examIdList, classId }) {
    let { data: averageData } = await SinavAPI.SinifKiyasAnaliziTumVeriler({
      examIdList,
      classId,
      action: 2
    });
    [averageData = {}] = averageData;

    let { data: allGains } = await SinavAPI.SinifKazanimKarnesiTumVeriler({
      examIdList,
      classId,
      action: 1
    });

    let { data: averageDataByMainLessons } = await SinavAPI.SinifKazanimKarnesiTumVeriler({
      examIdList,
      classId,
      action: 5
    });
    [averageDataByMainLessons = {}] = averageDataByMainLessons; // TODO: boş data geliyor

    const nets = parseNetsFromGainsTree(); // TODO
    const score = averageData.TYTPUAN;

    let treeDataByMainLesson = generateTreeData(allGains);
    treeDataByMainLesson = groupTreeDataByMainLesson(treeDataByMainLesson);
    treeDataByMainLesson = mergeTreeDataAndAverageNets(
      treeDataByMainLesson,
      averageDataByMainLessons
    );

    treeDataByMainLesson = treeDataByMainLesson.map(lesson => {
      const subLessons = lesson.subLessons || [];
      const correct = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.correct,
        0
      );
      const wrong = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.wrong,
        0
      );
      const empty = subLessons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.empty,
        0
      );
      const total = correct + wrong + empty;
      const correctnessPercent = (correct * 100) / total;

      return {
        ...lesson,
        correct,
        wrong,
        empty,
        correctnessPercent
      };
    });

    return {
      nets,
      score,
      treeDataByMainLesson
    };
  },

  async comparison({ examIdList, classId }) {
    const { data: classDataByExam } = await SinavAPI.SinifKiyasAnaliziTumVeriler({
      examIdList,
      classId,
      action: 1
    });

    let { data: classAverageData } = await SinavAPI.SinifKiyasAnaliziTumVeriler({
      examIdList,
      classId,
      action: 2
    });
    [classAverageData = {}] = classAverageData;

    const { data: averageDataByExam } = await SinavAPI.SinifKiyasAnaliziTumVeriler({
      examIdList,
      classId,
      action: 3
    });

    let { data: generalAverageData } = await SinavAPI.SinifKiyasAnaliziTumVeriler({
      examIdList,
      classId,
      action: 4
    });
    [generalAverageData = {}] = generalAverageData;

    const score = classAverageData.TYTPUAN;
    const averageScore = generalAverageData.PUAN_GN;
    const net = classAverageData.TPLM_N;
    const averageNet = generalAverageData.TPLM_GN;

    const averageScoresByExam = parseAverageScoresFromComparison(
      classDataByExam,
      averageDataByExam
    );

    const percentAndScore = parsePercentAndScoreFromComparison(); // TODO

    let mainLessonNetsByExam = parseMainLessonNetsFromComparison(
      classDataByExam,
      averageDataByExam
    );
    const mainLessonAverageNets = parseMainLessonAverageNetsFromComparison(
      classAverageData,
      generalAverageData
    );
    mainLessonNetsByExam = mergeMainLessonNetsFromComparison(
      mainLessonNetsByExam,
      mainLessonAverageNets
    );

    return {
      score,
      averageScore,
      net,
      averageNet,
      averageScoresByExam,
      percentAndScore,
      mainLessonNetsByExam,
      netsByLesson: [] // TODO: işlem 1 içinde ders ders net bilgisi yok
    };
  }
};
