<template>
  <div class="numbers-container">
    <button
      :style="numberStyles.totalQuestion"
      :class="lesson && 'detail-btn'"
      @click.prevent="showModal(values.gainId)"
      ref="treeDetailsShow"
    >
      {{ printValue("totalQuestion") }}
    </button>
    <span v-if="isLegend" :style="legendStyle" class="align-self-center">Soru Sayısı</span>
    <div :style="numberStyles.correct">
      {{ printValue("correct") }}
    </div>
    <span v-if="isLegend" :style="legendStyle" class="align-self-center">Doğru Sayısı</span>
    <div :style="numberStyles.wrong">
      {{ printValue("wrong") }}
    </div>
    <span v-if="isLegend" :style="legendStyle" class="align-self-center">Yanlış Sayısı</span>
    <div :style="numberStyles.empty">
      {{ printValue("empty") }}
    </div>
    <span v-if="isLegend" :style="legendStyle" class="align-self-center">Boş Sayısı</span>
    <div :style="getPercentStyle(printValue('percent'))">
      <small>%</small>{{ printValue("percent") }}
    </div>
    <span v-if="isLegend" :style="legendStyle" class="align-self-center">Başarı Yüzdesi</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isInt } from "@/common/utils";
export default {
  name: "gains-tree-label",
  props: {
    isTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    isLegend: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: Object,
      required: false,
      default: null
    },
    lesson: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    ...mapActions(["updateGainDetailId"]),
    printValue(key) {
      let title = null;
      switch (key) {
        case "totalQuestion":
          title = "SS";
          break;
        case "correct":
          title = "D";
          break;
        case "wrong":
          title = "Y";
          break;
        case "empty":
          title = "B";
          break;
        default:
          title = "";
      }
      if (this.shouldPrintValues) {
        if (isInt(this.values[key])) {
          return this.values[key];
        }
        return Number(this.values[key]).toFixed(1);
      }
      return title;
    },
    getPercentStyle(value) {
      let style = {};
      if (!this.shouldPrintValues) {
        style = this.numberStyles.percent;
      } else {
        style = value < 50 ? this.numberStyles.wrong : this.numberStyles.success;
      }

      return style;
    },
    showModal(id) {
      this.$root.$emit("bv::show::modal", "modal" + this.lesson, "#treeDetailsShow");
      this.updateGainDetailId(id);
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    numberStyles() {
      const { colors } = this.theme;
      const legendStyle = this.isLegend ? { height: "14px", width: "14px", marginLeft: 0 } : {};
      return {
        totalQuestion: {
          ...legendStyle,
          backgroundColor: colors.LIGHT_BLUE
        },
        correct: {
          ...legendStyle,
          backgroundColor: colors.BLUE
        },
        wrong: {
          ...legendStyle,
          backgroundColor: colors.RED
        },
        empty: {
          ...legendStyle,
          backgroundColor: colors.LIGHT_GRAY
        },
        percent: {
          ...legendStyle,
          background: `linear-gradient(90deg, ${colors.RED} 50%, ${colors.GREEN} 50%)`
        },
        success: {
          ...legendStyle,
          backgroundColor: colors.GREEN
        }
      };
    },
    legendStyle() {
      return {
        fontFamily: "GothamCondensedBook",
        color: this.theme.colors.GRAY,
        textTransform: "uppercase",
        marginLeft: "4px",
        marginRight: "4px",
        fontSize: "10.6px",
        lineHeight: "14px"
      };
    },
    shouldPrintValues() {
      return !this.isTitle && !this.isLegend;
    }
  }
};
</script>

<style lang="scss" scoped>
.numbers-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5px;
  margin-bottom: 0.5px;
  button,
  div {
    cursor: text;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 13px;
    width: 14px;
    border-radius: 1.2px;
    text-align: center;
    font-family: "GothamCondensedBook";
    font-size: 10.6px;
    line-height: 1;
    margin-left: 1px;
    color: white;
    @media (min-width: 768px) {
      margin-left: 10px;
    }
    small {
      font-size: 6.07px;
    }
  }
  .detail-btn {
    z-index: 1;
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    &:hover::before {
      background-color: rgba($color: white, $alpha: 0.5);
    }
  }
}
</style>
