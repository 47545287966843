import { COLORS } from "@/common/services/theme.service";
import { isInt } from "@/common/utils";

class DoughnutChartModel {
  constructor({
    title = "",
    correct = 0,
    wrong = 0,
    empty = 0,
    correctnessPercent = 0,
    studentNet = 0.0, // this data shouldn't be here. it's irrelevant. however no time for refactor
    averageNet = 0.0, // this data shouldn't be here. it's irrelevant. however no time for refactor
    rank = 0 // this data shouldn't be here. it's irrelevant. however no time for refactor
  }) {
    this.title = title;
    this.correct = correct;
    this.wrong = wrong;
    this.empty = empty;
    this.correctnessPercent = Math.round(correctnessPercent);
    this.studentNet = studentNet;
    this.averageNet = averageNet;
    this.rank = rank;

    this.isAllZero = this.correct === 0 && this.wrong === 0 && this.empty === 0;
  }

  toFixed(data) {
    return data.map(n => {
      if (isInt(n)) return n;

      try {
        return n.toFixed(2);
      } catch (e) {
        return n;
      }
    });
  }

  initializeDataset() {
    if (this.isAllZero) {
      return {
        labels: ["Doğru", "Yanlış", "Boş"],
        backgroundColor: [COLORS.BLUE, COLORS.RED, COLORS.GRAY],
        data: [this.correct, this.wrong, this.empty]
      };
    }

    const labels = [];
    const colors = [];
    const data = [];

    if (this.correct > 0) {
      labels.push("Doğru");
      colors.push(COLORS.BLUE);
      data.push(this.correct);
    }

    if (this.wrong > 0) {
      labels.push("Yanlış");
      colors.push(COLORS.RED);
      data.push(this.wrong);
    }

    if (this.empty > 0) {
      labels.push("Boş");
      colors.push(COLORS.GRAY);
      data.push(this.empty);
    }

    return {
      labels,
      backgroundColor: colors,
      data: this.toFixed(data)
    };
  }

  chartData() {
    const { labels, data, backgroundColor } = this.initializeDataset();
    return {
      title: this.title,
      chartData: {
        labels,
        datasets: [
          {
            data,
            backgroundColor
          }
        ]
      },
      percent: this.correctnessPercent,
      studentNet: this.studentNet,
      averageNet: this.averageNet,
      rank: this.rank
    };
  }
}

export default DoughnutChartModel;
